import { useLocation, useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import { useEffect, useState } from "react";
import { FormCheck } from "react-bootstrap";
import Services from "../../services/global.service";

const InformationsComplementaire = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const cible=location?.state
  console.log({cible})
  const [gratuit, setGratuit] = useState(false);
 
  const [comments, setComments] = useState("");
  const [suggestion, setSuggestion] = useState(0);
  const [price, setPrice] = useState(suggestion);

  const handlePriceChange = (e) => {
    // Remove non-numeric characters except decimal point
    const value = e.target.value.replace(/[^\d.]/g, "");
    setPrice(value);
  };
  const saveToLocalStorage = () => {
    // Retrieve the existing 'trajet' data from localStorage
    const existingTrajetData = JSON.parse(localStorage.getItem("detailsTrajet")) || {};
     const formatedPrice=!gratuit && parseFloat(price)
    // Update the 'trajet' data with the new information
    const updatedTrajetData = {
      ...existingTrajetData, // Preserve any existing data
      gratuit,               // Update 'gratuit' field
      formatedPrice,                 // Update 'price' field
      comments,              // Update 'comments' field
    };
  console.log(updatedTrajetData)
    // Save the updated 'trajet' data back to localStorage
    localStorage.setItem("detailsTrajet", JSON.stringify(updatedTrajetData));
  
    // Navigate to the next page after saving
   navigate("/cible",{state:cible});
  };
  const getDistance = async (obj) => {
    try {
      const res = await Services.create(obj, 'calcule-distance');
      console.log(res?.data);
      return parseFloat(res?.data?.distance) || 0; // Return distance or 0 if not present
    } catch (err) {
      console.error(err);
      return 0; // Return 0 on error
    }
  };
  
  useEffect(() => {
    const fetchData = async () => {
      const existingTrajetData = JSON.parse(localStorage.getItem("detailsTrajet")) || {};
      
      setGratuit(existingTrajetData?.gratuit);
      setPrice(existingTrajetData?.formatedPrice);
      setComments(existingTrajetData?.comments);
  
      const distance = await getDistance({
        latitude_depart: existingTrajetData.departLatLng?.lat,
        longitude_depart: existingTrajetData.departLatLng?.lng,
        latitude_destination: existingTrajetData.destinationLatLng?.lat,
        longitude_destination: existingTrajetData.destinationLatLng?.lng,
      });
  
      console.log('Distance:', distance);
  
      const prixKm = 0.11;
      const calculatedPrice = distance > 34 ? (distance * prixKm).toFixed(2) :3.75;
  
      setSuggestion(calculatedPrice);
      setPrice(calculatedPrice);
    };
  
    fetchData();
  }, []);
  
  return (
    <div className="bg-grey">
      <div className="stepInfo ">
        <div className="container-fluid-lg p-16">
          <h1 className="text-white d-flex align-items-center">
            <button onClick={() => navigate(-1)} className="cta">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </button>
            Votre trajet
          </h1>
        </div>
      </div>
      <h2 className="px-16 m-0 bleuM500 col-lg-6 mx-auto ">
        Informations complémentaires
      </h2>
      <div className="px-16 mt-24 col-lg-6 mx-auto ">
        <p className="subtitle bleuM500">Quel prix proposez-vous ?</p>
        <div className="mt-16">
          <div className="col">
            <div className="custom-switch custom-switch-label-io d-flex align-items-center">
              <FormCheck
                type="switch"
                id="custom-switch-pmr"
                className="form-switch mr-8"
                checked={gratuit}
                onChange={() => setGratuit(!gratuit)}
              />
              <label className="bleuM500 exergue" htmlFor="custom-switch-pmr">
                Réaliser ce trajet gratuitement
              </label>
            </div>
          </div>
        </div>

        {/* Price Input with € symbol */}
        {!gratuit && (
         <> <div className="floating-input-container mt-8">
            <input
              type="text"
              name="price"
              className="floating-input"
              placeholder=""
              value={price}
               min={1}
               defaultValue={suggestion}
              onChange={handlePriceChange}
            />
            <label htmlFor="price" className="floating-label home-label">
              Prix
            </label>
            <span className="euro-symbol">€</span>
          </div>
          <p className="legend bleuM500 mt-8 border-b pb-16">
          Prix proposé : {suggestion} €
          </p>
          </>
        )}
        <p className="subtitle bleuM500 mt-16">
        Avez-vous des choses à ajouter pour vos futurs passagers ? 
        </p>
        <div className="floating-input-container mt-8">
            <input as="textarea" name="comments" placeholder="" className="floating-input" value={comments} onChange={(e)=>{setComments(e.target.value)}} />
            <label htmlFor="comments" className="floating-label home-label">Commentaire pour vos passagers</label>
        </div>
      </div>
      <div className="h-xs-100"></div>
      <div className="bottom-white-block">
            <button type="submit" className="btn-primary-s p-16 w-sm-100"
            onClick={saveToLocalStorage}
            disabled={!price && !gratuit}
            >
              Suivant
            </button>
          </div>
    </div>
  );
};

export default InformationsComplementaire;
