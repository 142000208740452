import Amis from "./listAmis";
import Groups from "./listGroups";
import Tabs from "../../components/tabs";
import Header from "../../components/header";

const AmisAndGroupes=()=>{
    const tabs = [
        { key: "amis", label: "Mes ami(e)s", content: <Amis /> },
        { key: "groupes", label: "Mes groupes", content: <Groups /> },
      ];
    

    return (<>
       <div className="desktop">
        <Header connected={true} />
      </div>
    <Tabs tabs={tabs} className={"bg-grey"}/>

    </>
  );

}
export default AmisAndGroupes