import React, { useState } from "react";
import search from "../../assets/images/search.svg";
import closeBouton from "../../assets/images/closeBouton.svg";
import "../../assets/styles/messagerie.scss";

function Search({ placeholderText, onSearch, resetFiltered, delay = 500 }) {
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value); // Update inputValue state
    onSearch(value); // Trigger the onSearch callback
  };

  const clearInput = () => {
    setInputValue(""); // Reset the input value
    resetFiltered(); // Reset the filtered results
  };

  return (
    <div className="search-container mt-12">
      <img src={search} alt="Search" className="search-icon" />
      <input
        type="text"
        value={inputValue} // Bind the input to the inputValue state
        placeholder={placeholderText}
        onChange={handleInputChange} // Update the state on input change
        className="form-control search-input"
      />
      {inputValue && (
        <img
          src={closeBouton}
          alt="Clear search"
          className="search-icon"
          onClick={clearInput} // Clear the input and reset filter
        />
      )}
    </div>
  );
}

export default Search;
