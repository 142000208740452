import { Card, CardBody, CardImg } from "react-bootstrap";
const ItemCarousel = ({ data ,itemKey}) => {
  
  const isEvenKey = itemKey % 2 !== 0; 
  

  return (
    <Card className="card-atchoum p-0" >
      <CardImg src={data.image} alt="item img" className="p-16" />
      <div className={`border-dotted ${isEvenKey ? 'rotate180' : ''}`}>
        <p className={`${isEvenKey ? 'number-no-rotate' : ''}`}>{data?.id}</p>
      </div>
      <CardBody >
        <p className="textccm">{data?.text} </p>
      </CardBody>
    </Card>
  );
};
export default ItemCarousel;
