import "../../assets/styles/home.scss";
import { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
// import "core-js/stable/atob";
import Services from "../../services/global.service";
import Footer from "../../components/footer";
import Header from "../../components/header";
import Headerblue from "../../components/headerblue";
import { useLocation } from "react-router-dom";
import BlocTrajet from "../../components/blocTrajet";
import TopBar from "../../components/topBar";
import BlocResultEmpty from "../../components/BlocResultEmpty";

const SearchInterface = () => {
    const [connected, setConnected] = useState(null);
    const location=useLocation()
    const data=location?.state?.searchData
    const inputs_data=location?.state?.inputs_data
    console.log('data search', inputs_data)
    useEffect(() => {
        const auth = localStorage.getItem("token");
        if (auth) {

            Services.getAll("check-token")
                .then((res) => {
                    console.log('check', res.data);
                    if (res.data.status === "success") {
                        setConnected(true);
                    } else {
                        setConnected(false);
                    }
                })
                .catch((err) => {
                    setConnected(false);
                });
        } else {
            return setConnected(false);
        }
    }, [connected]);

    return (
        <>
             {connected!==null && <Header connected={connected}/>} 
            <Headerblue data={data.data} />
            <div className="bg-grey">
                 <div className="p-16 container-fluid-lg">
             {data.resultat.length>0  ? 
             <div className="row">
             {data.resultat.map((el)=>{
               return(<BlocTrajet data={el} path="/le-trajet/" inputs_data={inputs_data}  />   )
             })}
             <div className="col-lg-6 mx-auto">
                 <BlocResultEmpty title={"Aucun trajet ne vous convient ?"} 
             link={"/"}
             className={"exergue mt-26"}
             data={data}
             /> 
             </div>
            
              
             </div>
             : 
             <div className="col-lg-6 mx-auto">
             <BlocResultEmpty title={"Aucun résultat pour votre trajet recherché ..."} 
             link={"/"}
             className={"subtitle"}
             data={data}
             /> 
             </div>
             } 
            </div>
            <div className="h-xs-100"></div>
            </div>
           
            {connected && <TopBar />}
            {/* <Footer /> */}
        </>
    );
};
export default SearchInterface;
