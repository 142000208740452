import ConfirmationBloc from "../../components/confiramtionBloc"

const ConfirmationDemand=()=>{
return(
    <ConfirmationBloc
    title={'Merci, votre demande de trajet a bien été prise en compte'}
    text={"Une notification vous sera envoyé par e-mail et SMS lorsque le conducteur le validera. Vous recevrez par retour ses coordonnées téléphoniques et lui les votre."}
    link={'/mes-trajets'}
    cta={'Voir mes trajets'}
    />
)
}
export default ConfirmationDemand