import axios from "axios";
const API_URL = process.env.REACT_APP_BASE_URL || "";

// Get the token from localStorage
const token = localStorage.getItem('token');

// Create an Axios instance with the token and common headers
// const apiClient = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
//   headers: {
//     Authorization: `Bearer ${token}`,
//     'Access-Control-Allow-Origin': '*',
//     'Accept': 'application/json',
//   },
// });

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${token}`,  // Vérifiez que le token est bien défini
    'Access-Control-Allow-Origin': '*',
    'Accept': 'application/json',
  },
});



// Headers specifically for file uploads
const headersFilesMultipart = {
  headers: {
    'Accept': 'application/json',
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
};

// Updated service functions to use apiClient

const getAll = (endpoint) => {
  return apiClient.get(endpoint);
};

const getAllByData = (endpoint, data) => {
  return apiClient.post(endpoint, data);
};

const getAllPanier = (endpoint, data) => {
  return apiClient.get(endpoint, { data });
};

const getOne = (id, endpoint) => {
  return apiClient.get(`${endpoint}/${id}`);
};

const getOneByParams = (endpoint) => {
  return apiClient.get(endpoint);
};

const create = (data, endpoint) => {
  // console.log(token)
  return apiClient.post(endpoint, data);
};

const createMultipart = (data, endpoint) => {
  return apiClient.post(endpoint, data, headersFilesMultipart);
};

const deleteByid = (id, endpoint) => {
  return apiClient.delete(`${endpoint}/${id}`);
};

const deleteBody = (data, endpoint) => {
  return apiClient.delete(endpoint, { data });
};

const update = (data, id, endpoint) => {
  return apiClient.post(`${endpoint}/update/${id}`, data);
};
const updatePut = (endpoint) => {
  return apiClient.put(`${endpoint}`);
};
const updatewithoutID = (data, endpoint) => {
  return apiClient.post(endpoint, data);
};

const getByRole = (endpoint, role) => {
  return apiClient.get(`${endpoint}/${role}`);
};

// Exporting the services object
const Services = {
  getAll,
  getOne,
  create,
  createMultipart,
  deleteByid,
  deleteBody,
  update,
  updatewithoutID,
  getByRole,
  getAllByData,
  getOneByParams,
  getAllPanier,
  updatePut
};

export default Services;
