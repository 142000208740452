// utils.js

import Services from "../services/global.service";

/**
 * Formats a phone number by adding spaces every two digits.
 * @param {string} value - The input string (phone number).
 * @returns {string} - The formatted phone number.
 */
export const formatPhoneNumber = (value) => {
    const digits = value.replace(/\D/g, ""); // Remove non-digits
    return digits.replace(/(\d{2})(?=\d)/g, "$1 ").trim(); // Add spaces every two digits
};
  
  /**
   * Capitalizes the first letter of each word in a string.
   * @param {string} value - The input string.
   * @returns {string} - The capitalized string.
   */
  export const capitalizeWords = (value) => {
    return value
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter
  };
  
  /**
   * Validates if a string contains only alphabetic characters.
   * @param {string} value - The input string.
   * @returns {boolean} - True if the string is alphabetic, otherwise false.
   */
  export const isAlphabetic = (value) => {
    return /^[a-zA-Z\s]+$/.test(value); // Check for only letters and spaces
  };
  export const isAuth=()=>{
    const auth = localStorage.getItem("token");

    if (auth) {
      // Validate token by calling the backend service
      Services.getAll("check-token")
        .then((res) => {
          if (res.data.status === "success") {
            return(true); // Token is valid
          } else {
            localStorage.clear();
            return(false); // Token invalid or expired
          }
        })
        .catch(() => {
          localStorage.clear();
          return(false); // Error in token validation
        })
        .finally(() => {
          return(false); // End loading state after validation
        });
    } else {
      return(false); // No token found
 // End loading state
    }
  }
  export const validateFiles = (files) => {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;
    const maxFileSize = 2 * 1024 * 1024; // 2 MB
  
    return files.every((file) => 
      allowedExtensions.test(file.name) && file.size <= maxFileSize
    );
  };
  