import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import IconClose from "../assets/images/iconClose.svg";
import Form from "react-bootstrap/Form";
import Services from "../services/global.service";
import { useNavigate } from "react-router-dom";

function Quitter({ open, setOpen ,members, group_id }) {
  const [selectedAdmin, setSelectedAdmin] = useState(""); // Track selected admin
  const navigate=useNavigate()
  const handleSubmit = () => {
    if (!selectedAdmin) {
      // If no admin is selected, display an alert or show a validation message
      alert("Veuillez sélectionner un membre du groupe comme nouvel administrateur.");
      return;
    }

    const data = {
      idAdmin: selectedAdmin, // Use selected admin's ID
    };

    Services.create(data, `groupes/quitter-groupe/${group_id}`)
      .then((res) => {
        console.log(res);
        setOpen(!open); // Close modal after successful submission
        navigate('/amis-groupes')
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500">Quitter le groupe</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>

      <Modal.Body>
        <p className="exergue bleuM500">
          Veuillez choisir un membre du groupe à qui vous souhaitez transférer
          les droits d'administration. Cette personne deviendra le nouvel
          administrateur du groupe.
        </p>
        <div className="floating-input-container">
          <select
            className="floating-input input-home mt-8"
            aria-label="Default select example"
            value={selectedAdmin} // Bind the value of the select to state
            onChange={(e) => setSelectedAdmin(e.target.value)} // Update state on change
          >
            <option value="">Membre du groupe</option>
            {members
            .filter((el)=>(el?.group_user?.id!=localStorage.getItem('userData')))
            .map((member) => (
              <option
                key={member?.group_user?.id}
                value={member?.group_user?.id}
                style={{ backgroundColor: '#f9f9f9', color: '#555' }}
              >
                {member?.group_user?.prenom} {member?.group_user?.nom}
              </option>
            ))}
          </select>
        </div>
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button className="btn-primary-s p-14 w-100" onClick={handleSubmit}>
          Quitter le groupe
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Quitter;
