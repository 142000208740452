import { ErrorMessage, Formik, Field } from "formik";
import * as Yup from "yup"; // Pour la validation du formulaire
import ReturnIcon from "../../assets/images/return.svg";
import Alert from "../../assets/images/danger.svg";
import Header from "../../components/header";
import { Form } from "react-bootstrap";
import Services from "../../services/global.service";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";

const CreateGroup = () => {
  const navigate = useNavigate();
  // Schéma de validation avec Yup
  const GroupSchema = Yup.object().shape({
    groupName: Yup.string().required("Le nom du groupe est requis").min(3, "Le nom du groupe doit comporter au moins 3 caractères"),
    groupDescription: Yup.string(),
  });

  // Gestion de la soumission du formulaire
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    // Appelez votre service pour créer le groupe avec les valeurs du formulaire
    // console.log(values)
    Services.createMultipart(values, "/groupes/create")
      .then((response) => {
        toast.success("Groupe créé avec succès !");
        console.log(response?.data?.groupe?.id);
        navigate("/group/add/membre/" + response?.data?.groupe?.id);
      })
      .catch((error) => {
        toast.error("Erreur lors de la création du groupe.");
        console.error(error);
      })
      .finally(() => {
        setSubmitting(false); // Arrête l'indicateur de chargement
      });
  };

  return (
    <>
      <div className="desktop">
        <Header connected={true} />
      </div>

      <div className="bg-grey">
        <div className="step5">
          <div className="container-fluid-lg">
            <h1 className="text-white p-16 d-flex align-items-center mb-100">
            <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Créer un groupe
            </h1>
          </div>
        </div>

        <div className="mt-16 col-lg-6 mx-auto">
          <p className="px-16 mt-8 subtitle bleuM500 text-start">
            Créez un groupe, et tenez-vous au courant des trajets !
          </p>

          {/* Formulaire Formik pour créer le groupe */}
          <Formik
            initialValues={{ groupName: "", groupDescription: "" }}
            validationSchema={GroupSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleSubmit,values }) => (
              <Form onSubmit={handleSubmit} className="px-16 mt-4">
                {/* Champ Nom du Groupe */}
                <div className="floating-input-container mt-16">
                  <Field
                    type="text"
                    name="groupName"
                    className={`floating-input radius-top`}
                    id="groupName"
                    placeholder=""
                  />
                  <label htmlFor="groupName" className="floating-label">
                    Nom du groupe
                  </label>

                 
                </div>

                {/* Champ Description du Groupe */}
                <div className="floating-input-container ">
                  <Field
                    name="groupDescription"
                    as="textarea"
                    className={`floating-input radius-bottom`}
                    placeholder=""
                  />
                  <label htmlFor="groupName" className="floating-label">
                    Description du groupe
                  </label>
                  <ErrorMessage
                    name="groupDescription"
                    component="div"
                    className="text-danger"
                  />
                   <ErrorMessage name="groupName">
                    {(msg) => (
                      <div className="error-message">
                        <img src={Alert} alt="Alert" />
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </div>

                {/* Bouton de soumission */}

                <div className="bottom-white-block mt-4">
                  <div className="text-center">
                    <button
                      type="submit"
                      onClick={handleSubmit}
                      className="btn-primary-s p-16 w-sm-100 mt-2"
                      disabled={isSubmitting || values?.groupName===""}
                    >
                      {isSubmitting ? "Création..." : "Créer le groupe"}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default CreateGroup;
