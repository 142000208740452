import ReturnIcon from "../../assets/images/return.svg";
import Header from "../../components/header";
import Services from "../../services/global.service";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Search from "../messagerie/search";
import Friend from "../messagerie/friend";
import { useNavigate, useParams } from "react-router-dom";
import Membres from "./members";

const Addmembre = () => {
    const [amis, setAmis] = useState([]);
    const [filteredAmis, setFilteredAmis] = useState([]);
    const params = useParams();
  const navigate = useNavigate();

    const getList = () => {
        Services.getAll('amis/group/' + params.id)
            .then((res) => {
                const data = Array.isArray(res?.data) ? res?.data : [];
                setAmis(data);
                console.log(data)
                setFilteredAmis(data);
            })
            .catch((err) => {
                console.error("Error fetching friends:", err);
            });
    };


  
    useEffect(() => {
        getList();
    }, []);

    return (
        <>
            <div className="desktop">
                <Header connected={true} />
            </div>

            <div className="bg-grey">
                <div className="stepAssurance">
                    <div className="container-fluid-lg">
                        <h1 className="text-white p-16 d-flex align-items-center mb-100">
                        <button onClick={() => navigate(-1)} className="cta">
                                <img src={ReturnIcon} className="mr-8" alt="return icon" />
                            </button>
                            Créer un groupe
                        </h1>
                    </div>
                </div>
                <Membres reloadData={getList} amis={amis} filteredAmis={filteredAmis} setFilteredAmis={setFilteredAmis} withBtn={true}/>
            </div>
        </>
    );
};

export default Addmembre;
