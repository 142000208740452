import { useState } from "react";
import TopBar from "./topBar";


const Tabs = ({ tabs, className }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].key); // Start with the first tab

  return (
    <div className="mes-trajets-container">
      <div className="mes-trajets-tabs w-100" style={{ display: "block" }}>
        <ul className="row">
          {tabs.map((tab) => (
            <li
              key={tab.key}
              className={activeTab === tab.key ? "active col" : "col"}
              onClick={() => setActiveTab(tab.key)}
            >
              {tab.label}
            </li>
          ))}
        </ul>
      </div>
      <div className={className}>
        {tabs.map(
          (tab) =>
            activeTab === tab.key && <div key={tab.key}>{tab.content}</div>
        )}
      </div>
      <TopBar />
    </div>
  );
};

export default Tabs;
