
import './App.scss';
import '../src/assets/styles/ui.scss'
import '../src/assets/styles/colors.scss'
import '../src/assets/styles/btn.scss'
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-calendar/dist/Calendar.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//import views
import Home from './views/home';
import paymentMethod from './views/demandeTrajet/paymentMethod';
import Login from './views/auth/login';
import ResetPassword from './views/auth/resetPassword';
import SendCode from './views/auth/sendCode';
import NewPassword from './views/auth/nvPassword';
import StepOneRegister from './views/register/stepOneRegistre';
import MyPorfile from './views/profile/myProfile';
import StepTwoRegister from './views/register/secondStepRegister';
import Register from './views/register/lastStepRegister';
import AboutMe from './views/profile/aboutMe';
import MyDocs from './views/profile/myDocs';
import MyCar from './views/profile/myCar';
import Deplacement from './views/profile/deplacement';
import Informations from './views/profile/informations';
import ChangePassword from './views/profile/changePassword';
import Notifs from './views/profile/notifs';
import BankAccount from './views/profile/bankAccount';
import ProtectedRoutes from './utils/ProtectedRoutes';
import MyPaiements from './views/profile/myPaiements';
import Bulletin from './views/solidaire/bulletin';
import Apropos from './views/solidaire/aporpos';
import Photo from './views/solidaire/photo';
import Car from './views/solidaire/car';
import Perimetre from './views/solidaire/perimetre';
import SearchInterface from './views/search/searchInterface';
import { useEffect } from 'react';
import Docs from './views/solidaire/docs';
import Confirmation from './views/solidaire/confirmation';
import Assurance from './views/solidaire/assurance';
import DetailsTrajet from './views/covoiturage/detailsTrajet';
import InformationsComplementaire from './views/covoiturage/informationComplementaire';
import Cible from './views/covoiturage/cible';
import ConfirmationTrajet from './views/covoiturage/confirmation';
import LeTrajet from './views/search/detailsTrajet';
import ConfirmationDemand from './views/search/confirmationDemand';
import PaymentMethod from './views/demandeTrajet/paymentMethod';
import Tickets from './views/demandeTrajet/tickets';
import MesTrajets from './views/mesTrajets/mestrajets';
import Trajet from './views/mesTrajets/letrajet';
import SearchInterfaceSolidaire from './views/search/searchInterfaceSolidaire';
import LeConducteur from './views/search/leconducteur';
import ListMessages from './views/messagerie/listMessages';
import Discussion from './views/messagerie/discussion';
import ListFriends from './views/messagerie/listFriends';
import TabsAmisGroupes from './components/tabs';
import PublicProfil from './views/amisAndGroupes/publicProfil';
import CreateGroup from './views/amisAndGroupes/createGroup';
import Addmembre from './views/amisAndGroupes/addMembre';
// import DetailGroups from './views/amisAndGroupes/detailGroups';
import AmisAndGroupes from './views/amisAndGroupes/amisAndGroupes';
import InvitMembres from './views/amisAndGroupes/invitAmis';
import GroupProfil from './views/amisAndGroupes/groupProfil';
import Cgu from './views/cgu';
import Ml from './views/ml';
import NotFoundRoute from './views/404';
function App() {

  return (
    <div className="App">
      <ToastContainer />
      <BrowserRouter>
        <Routes>
        <Route path="*" element={<NotFoundRoute />} />
          <Route path="/" element={<Home />} />
          <Route path="/cgu" element={<Cgu />} />
          <Route path="/mentions-legales" element={<Ml />} />
          <Route path="/login" element={<Login />} />
          <Route path="/reset" element={<ResetPassword />} />
          <Route path="/sendCode" element={<SendCode />} />
          <Route path="/new-password" element={<NewPassword />} />
          {/* <Route path="/register" element={<StepOneRegister />} /> */}
          <Route path="/register/:token?" element={<StepOneRegister />} />
          <Route path="/verify-code" element={<StepTwoRegister />} />
          <Route path="/last-step-register" element={<Register />} />
          <Route path="/search" element={<SearchInterface />} />
          <Route path="/search-solidaire" element={<SearchInterfaceSolidaire />} />
          <Route path="/conducteur/:id" element={<LeConducteur />} />
          <Route path="/le-trajet/:id" element={<LeTrajet />} />
          <Route path="/details-covoiturage" element={<DetailsTrajet />} />
          <Route element={<ProtectedRoutes role="" />}>
            <Route path="/mon-profil" element={<MyPorfile />} />
            <Route path="/aboutme" element={<AboutMe />} />
            <Route path="/myDocs" element={<MyDocs />} />
            <Route path="/ma-vehicule" element={<MyCar />} />
            <Route path="/deplacement" element={<Deplacement />} />
            <Route path="/informations" element={<Informations />} />
            <Route path="/changement-motdepasse" element={<ChangePassword />} />
            <Route path="/notifs" element={<Notifs />} />
            <Route path="/paiements" element={<MyPaiements />} />
            <Route path="/compte-bancaires" element={<BankAccount />} />
            <Route path="/bulletin-adhesion" element={<Bulletin />} />
            <Route path="/a-propos-conducteur-solidaire" element={<Apropos />} />
            <Route path="/photo" element={<Photo />} />
            <Route path="/vehicule-solidaire" element={<Car />} />
            <Route path="/perimetre-solidaire" element={<Perimetre />} />
           
            
            <Route path="/confirmation-demande" element={<ConfirmationDemand />} />
            <Route path="/docs-solidaire" element={<Docs />} />
            <Route path="/assurance-solidaire" element={<Assurance />} />
            <Route path="/confirmation-solidaire" element={<Confirmation />} />
            
            <Route path="/information-complementaire" element={<InformationsComplementaire />} />
            <Route path="/cible" element={<Cible />} />
            <Route path="/confirmation-trajet" element={<ConfirmationTrajet />} />
            <Route path="/paymentMethod/:id" element={<PaymentMethod/>}  />
            <Route path="/paymentMethod" element={<PaymentMethod/>}  />
            <Route path="/tickets-mobilite" element={<Tickets/>}  />
            <Route path="/mes-trajets" element={<MesTrajets/>}  />
            <Route path="/mes-trajets/:id/:idReservation" element={<Trajet/>}  />
            <Route path="/mes-trajets/:id" element={<Trajet/>}  />
            {/* messagerie */}
            <Route path="/mes-messages" element={<ListMessages/>}  />
            <Route path="/discussion/:idDiscussion" element={<Discussion/>}  />
            <Route path="/listAmis" element={<ListFriends/>}  />
            {/* Groupes et amis */}
            <Route path="/amis-groupes" element={<AmisAndGroupes/>}  />
            <Route path="/group/add" element={<CreateGroup/>}  />
            <Route path="/group/add/membre/:id" element={<Addmembre/>}  />
            {/* <Route path="/group/:id" element={<DetailGroups />}  /> */}
            <Route path="/amis/:id" element={<PublicProfil/>}  />
            <Route path="/group/invit/:id" element={<InvitMembres/>}  />
            <Route path="/group/:id" element={<GroupProfil/>}  />

          </Route>

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
