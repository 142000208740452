import { useNavigate, useParams } from "react-router-dom";

import Services from "../../services/global.service";

import ReturnIcon from "../../assets/images/return.svg";
import CreditCard from "../../assets/images/credit-card-1.svg";
import Tickets from "../../assets/images/ticket-check.svg";
import ArrowUp from '../../assets/images/arrow-up.svg'

import { useEffect } from "react";
import { useState } from "react";
import RaisonSignal from "../../components/modalRaison";
import Conducteur from "../../components/conducteur";
import CardTrajet from "../../components/trajetCard";
import Cancele from "../../components/canceleTrajet";
import { Collapse } from "react-bootstrap";
const Trajet = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [trajet, setTrajet] = useState();
  const [clickedReservationId, setClickedReservationId] = useState(null);
  const reservation = trajet?.trajet_reservation.filter(
    (pa) => pa?.user_id == localStorage.getItem("userData")
  );
  console.log({reservation})
  const [open, setOpen] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [raison, setRaison] = useState("");
  const [cancelModal, setCancelModal] = useState(false);
  const getTrajet = () => {
    Services.getOne(params?.id, "trajet/getOne")
      .then((res) => {
        console.log(res);
        setTrajet(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateStatus=(status, reservationId)=>{
    const data={
      status,
      idReservation:reservationId
    }   
    console.log({data})
    Services.create(data,'changeStatus')
    .then((res)=>{
      console.log(res.data)
      setClickedReservationId(reservationId)
    })
    .catch((err)=>{
      console.log(err)
    })
    }
  useEffect(() => {
    getTrajet();
    //getDemand()
  }, [clickedReservationId]);
  return (
    <>
      {open && (
        <RaisonSignal
          open={open}
          setOpen={setOpen}
          raison={raison}
          setRaison={setRaison}
          user={trajet.user_id}
        />
      )}
      {cancelModal &&  (
        <Cancele
          open={cancelModal}
          setOpen={setCancelModal}
          idTrajet={trajet?.id}
         // idReservation={trajet?.conducteurtrajet==localStorage.getItem('userData')?null:reservation[0]?.id}
          text={trajet?.conducteurtrajet==localStorage.getItem('userData')?"Prévenez le passager de la raison de l’annulation de votre trajet.":"Prévenez le conducteur de la raison de l’annulation de votre trajet."}
        />
      )}
      <div className="bg-grey le-trajet">
        <div className="bg-atchoum-profil">
          <div className="container-fluid-lg col-lg-6 mx-auto p-16">
            <div className="col-auto">
              <h1 className="text-white d-flex">
                <button onClick={() => navigate(-1)} className="cta">
                  <img src={ReturnIcon} className="mr-8" alt="return icon" />
                </button>
                Le trajet
              </h1>
            </div>
            <div className="mt-24">
              <CardTrajet
                trajet={trajet}
                from="myTargets"
                trajetType={"aller"}
              />
            </div>

            <div className="mt-8"></div>
            {trajet?.dateRetour || trajet?.isFlexibleDest ? (
              <CardTrajet
                trajet={trajet}
                from="myTargets"
                trajetType={"retour"}
              />
            ) : (
              ""
            )}

            <h2 className="bleuM500 mt-24">Le prix</h2>
            {trajet?.conducteurtrajet?.id != localStorage.getItem("userData") ? (
              <div className="card-atchoum bg-white text-start">
                  <span className="current-text">
                      {trajet?.typeTrajet===1 ?
                      "Le conducteur a fixé le prix pour un passager à "
                      :"Trajet solidaire : le prix, calculé au kilométrage, est de"
                      } 
                  </span>  
                  <span className="exergue bleuM500 mr-8">
                   {parseFloat(trajet?.prixTrajet)?.toFixed(2) || 0}€
                  </span>
               
              </div>
            ) : (
              <div className="card-atchoum bg-white text-start">
                <span className="current-text bleuM500 mr-8">
                  Vous recevrez{" "}
                  <span className="exergue bleuM500">
                    {parseFloat(trajet?.prixTrajet)?.toFixed(2)  || 0}€{" "}
                  </span>{" "}
                  pour ce trajet
                  <span className="legend bleu400">
                    {" "}
                    (Prix pour 1 passager)
                  </span>{" "}
                </span>
              </div>
            )}
            {trajet?.commentaireTrajet && (
              <>
                {trajet?.typeTrajet === 0 ? (
                  <h2 className="bleuM500 mt-24">Commentaire du passager</h2>
                ) : (
                  <h2 className="bleuM500 mt-24">Commentaire du conducteur</h2>
                )}
                <div className="card-atchoum bg-white text-start ">
                  <p className="current-text mb-0">
                    {trajet?.commentaireTrajet}
                  </p>
                </div>
              </>
            )}
            {trajet?.conducteurtrajet?.id != localStorage.getItem("userData") && reservation? (
              <Conducteur
                conducteur={trajet?.conducteurtrajet}
                open={open}
                setOpen={setOpen}
                title={true}
                type="conducteur"
                withBtn={false}
                reservation={reservation[0]}
              />
            ) : (
              trajet?.trajet_reservation  && (
                <>
                  {/* Section for Demandes */}
                  { trajet?.trajet_reservation?.filter((pa) => pa?.status === "onHold").length > 0 && (
                    <h2 className="bleuM500 mt-24">
                        {trajet?.typeTrajet === 1 ? "Demandes" : "Le passager"}
                    </h2>
                )}
                
                  {trajet?.trajet_reservation
                    ?.filter((pa) => pa?.status === "onHold")
                    .map((pa) => (
                      <div className="mt-8">
                        <Conducteur
                          conducteur={pa?.reservation_user}
                          open={open}
                          setOpen={setOpen}
                          title={false}
                          type="passager"
                          reservation={pa}
                          withBtn={trajet?.typeTrajet===1?true:false}
                          updateStatus={updateStatus}
                          
                        />
                      </div>
                    ))}

                  {/* Section for Les passagers */}
                  { trajet?.trajet_reservation?.filter((pa) => pa?.status === "accepted").length > 0 && (
                    <h2 className="bleuM500 mt-24">
                        {trajet?.typeTrajet === 1 ? "Les passagers" : "Le passager"}
                    </h2>
                )}
  
                  
                  {trajet?.trajet_reservation
                    ?.filter((pa) => pa.status === "accepted")
                    .map((pa) => (
                      <div className="mt-8">
                        <Conducteur
                          conducteur={pa?.reservation_user}
                          open={open}
                          setOpen={setOpen}
                          title={false}
                          type={trajet?.typeTrajet === 1 ?"passager":"conducteur"}
                          reservation={pa}
                          withBtn={false}
                        />
                      </div>
                    ))}
                </>
              )
            )}

            {trajet?.conducteurtrajet?.id != localStorage.getItem("userData") && reservation
            && reservation[0]?.methodPaiement !== null 
            && (
              <>
                <h2 className="bleuM500 mt-24">Mode de paiement</h2>
                {reservation
                 && reservation[0]?.methodPaiement === "ticket" 
                 ? (
                  
                    <div  className={`faq-item ${openPayment ? '' : ''}`}>
                        <button
                          onClick={() => setOpenPayment(!openPayment)}
                          aria-controls={`faq-collapse-text`}
                          aria-expanded={openPayment}
                          className={`faq-title w-100 d-flex justify-content-between align-items-center ${openPayment ? 'border-b pb-16' : ''}`}
                        >
                         <div>
                         <img src={Tickets} className="mr-8" alt="tickets icon" />6tickets mobilités
                          </div> 
                          <span className={`arrow ${openPayment? 'open' : ''}`}><img src={ArrowUp} alt="arrow up icon" /></span>
                        </button>
                        <Collapse in={openPayment}>
                          <div id={`faq-collapse-text`} className="faq-content mt-16">
                            {reservation && reservation[0]?.reservation_ticket.map((el)=>{
                              return(<p className="current-text bleu400">N°{el.refTicket}</p>)
                            })}
                          </div>
                        </Collapse>
                      </div>
                  
                ) : (
                  <div className="card-atchoum bg-white text-start ">
                    <p className="current-text mb-0">
                      {" "}
                      <img
                        src={CreditCard}
                        className="mr-8"
                        alt="credit Card icon"
                      />
                      CB finissant par {reservation[0]?.reservation_strip?.numCard}
                    </p>
                  </div>
                )}
              </>
            )}
           {trajet?.conducteurtrajet?.id==localStorage.getItem('userData') &&
           <div className="text-center mt-32">
           <button
             className="cta text-underline"
             onClick={() => setCancelModal(!cancelModal)}
           >
             Annuler le trajet
           </button>
         </div>
           } 
          </div>
        </div>
      </div>
      {trajet?.typeTrajet===0 &&trajet?.conducteurtrajet?.id == localStorage.getItem("userData") && trajet?.trajet_reservation[0]?.status==="onHold" &&
      <>
      <div className="h-xs-100"></div>
           <div className="bottom-white-block">
           <button className="btn-primary-s p-16 w-100"
           onClick={()=>updateStatus('accepted',trajet?.trajet_reservation[0]?.id)}
           >
             Accepter le trajet
           </button>
           <button className="btn-secondary-s p-14 mt-8 w-100"
           onClick={()=>updateStatus('refused',trajet?.trajet_reservation[0]?.id)}
           
           >
           Refuser le trajet
           </button>
         </div>
         </>
      }
     
    </>
  );
};
export default Trajet;
