import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// Import assets
import "../../assets/styles/login.scss";
import EyeOpened from "../../assets/images/open-eye.svg";
import EyeClosed from "../../assets/images/eyeClosed.svg";
import Alert from "../../assets/images/danger.svg";
import Check from "../../assets/images/check-1.svg";
import ReturnIcon from "../../assets/images/return.svg";
import { useNavigate, useParams } from "react-router-dom";
import Services from "../../services/global.service";
import { toast } from "react-toastify";

const StepOneRegister = () => {
    const [showPassword, setShowPassword] = useState(false);
    const {token}=useParams()
    const navigate=useNavigate()
    const initialValues = {
      email: "",
      password: "",
    };

  // Validation schema using Yup
    const validationSchema = Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Le mot de passe doit contenir au moins 8 caractères")
        .matches(
          /[a-z]/,
          "Le mot de passe doit contenir au moins une lettre minuscule"
        )
        .matches(
          /[A-Z]/,
          "Le mot de passe doit contenir au moins une lettre majuscule"
        )
        .matches(
          /\d|[!@#$%^&*(),.?":{}|<>]/,
          "Le mot de passe doit contenir au moins un chiffre ou un caractère spécial"
        )
        .required("Le mot de passe est obligatoire"),
    });

    const handleToggle = () => {
      setShowPassword(!showPassword);
    };

  // Handle form submission
    const handleSubmit = (values, { setSubmitting }) => {
      console.log("Form data hereeee:", values);
      // Simulate async operation
      Services.create({...values,tokenInvite:token},'register')
      .then((res)=>{
        console.log(res.data)
        localStorage.setItem('register_code' , res?.data?.user?.register_code)
        localStorage.setItem('token' , res?.data?.token)
        window.location.href='/verify-code'
      })
      .catch((err)=>{
        console.log(err)
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'

        });
      })
    
      setTimeout(() => {
        setSubmitting(false);
      }, 1000);
    };
    return (
      <div className="bg-grey">
        <div className="stepOneBg ">
          <div className="container-fluid-lg p-16">
          <h1 className="text-white p-16 d-flex">
          <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
          Inscription
        </h1>
          </div>
      
        
        </div> 
        <h2 className="p-16 col-lg-4 mx-auto container-fluid-lg ">
            Créez votre mot de passe
          </h2>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, values , isValid, dirty}) => {
            const isLongEnough = values.password.length >= 8;
            const hasUppercase = /[A-Z]/.test(values.password);
            const hasLowercase = /[a-z]/.test(values.password);
            const hasNumberOrSpecial = /\d|[!@#$%^&*(),.?":{}|<>]/.test(
              values.password
            );
            return (
              <Form className="mt-8 ">
                <div className="p-16  col-lg-4 mx-auto">
                  {/* Email Field */}
                  <div className="floating-input-container">
                    <Field
                      type="email"
                      name="email"
                      className={`floating-input radius-top ${
                        touched.email && errors.email ? "is-invalid" : ""
                      }`}
                      id="email"
                      placeholder=" " // important for floating effect
                    />
                    <label htmlFor="email" className="floating-label">
                      Adresse e-mail
                    </label>
                  </div>

                  {/* Password Field */}
                  <div className="floating-input-container position-relative">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      className={`floating-input radius-bottom ${
                        touched.password && errors.password ? "is-invalid" : ""
                      }`}
                      id="password"
                      placeholder=" " // important for floating effect
                    />
                    <label htmlFor="password" className="floating-label">
                      Mot de passe
                    </label>
                    <div
                      className="password-toggle-icon position-absolute"
                      style={{
                        right: "10px",
                        top: "50%",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={handleToggle}
                    >
                      {showPassword ? (
                        <img src={EyeOpened} alt="Hide Password" />
                      ) : (
                        <img src={EyeClosed} alt="Show Password" />
                      )}
                    </div>
                  </div>
                  <ErrorMessage name="email" >
                    {msg => (
                      <div className="error-message">
                        <img src={Alert} alt="Alert" />
                        {msg}
                      </div>
                    )}
                      </ErrorMessage>
                </div>
              
                {/* <ErrorMessage name="password" >
                    {msg => (
                      <div className="error-message">
                        <img src={Alert} alt="Alert" />
                        {msg}
                      </div>
                    )}
                      </ErrorMessage> */}
                {/* <ErrorMessage
                  name="password"
                  component="div"
                  className="invalid-feedback"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="invalid-feedback"
                /> */}
                <div className="regles text-start p-16 col-lg-4 mx-auto">
                  {/* Password length rule */}
                  <p
                    className={`legend bleuM500 ${
                      touched.password && !isLongEnough
                        ? "text-danger"
                        : "text-muted"
                    }`}
                  >
                    <img src={Check} alt="icon check" className="mr-8" /> Plus de
                    8 caractères
                  </p>

                  {/* Uppercase and lowercase letter rule */}
                  <p
                    className={`legend bleuM500 ${
                      touched.password && !(hasUppercase && hasLowercase)
                        ? "text-danger"
                        : "text-muted"
                    }`}
                  >
                    <img src={Check} alt="icon check" className="mr-8" /> Au
                    minimum une majuscule et une minuscule
                  </p>

                  {/* Number or special character rule */}
                  <p
                    className={`legend bleuM500 ${
                      touched.password && !hasNumberOrSpecial
                        ? "text-danger"
                        : "text-muted"
                    }`}
                  >
                    <img src={Check} alt="icon check" className="mr-8" /> Un
                    chiffre ou un caractère spécial
                  </p>
                </div>
                <div className="bottom-white-block">
                  <p className="current-text bleu600  text-center-lg">
                    Déjà un compte ?{" "}
                    <a href="/login" className="cta">
                      Je me connecte
                    </a>
                  </p>
                  <button type="submit"  disabled={!(isValid && dirty)} className="btn-primary-s mt-24 p-16 w-sm-100">
                    Suivant
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
};
export default StepOneRegister;
