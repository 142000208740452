import ConfirmationBloc from "../../components/confiramtionBloc"

const ConfirmationTrajet=()=>{
return(
    <ConfirmationBloc
    title={'Votre trajet a bien été publié !'}
    text={"Vous recevrez une notification par e-mail et SMS dès qu'un passager effectuera une demande de réservation"}
    link={'/mes-trajets'}
    cta={'Voir mes trajets'}
    />
)
}
export default ConfirmationTrajet