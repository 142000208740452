import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import IconClose from "../assets/images/iconClose.svg";
import Form from "react-bootstrap/Form";
import Services from "../services/global.service";

function Actions({ open, setOpen,action,setAction, onAction,created_by }) {


  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500"></h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>

      <Modal.Body className="pt-0">
       
      <Form>
          <div className="mb-3">
            <label className="d-flex align-items-center border-b pb-16">
              <input
                type="radio" 
                id="quitter" 
                name="document-type"
                value="quitter"
                checked={action === "quitter"}
                onChange={(e) => setAction(e.target.value)}
              />
              <span className="custom-radio"></span> Quitter le groupe
            </label>
          </div>
          {
         created_by==localStorage.getItem('userData') &&
          <div className="mb-3">
            <label className="d-flex align-items-center">
              <input
                type="radio" 
                id="delete" 
                name="document-type"
                value="delete"
                checked={action === "delete"}
                onChange={(e) => setAction(e.target.value)}
              />
              <span className="custom-radio"></span> Supprimer le groupe
            </label>
          </div>
         }
         
        </Form>
        
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button className="btn-primary-s p-14 w-100" onClick={onAction}>
        Confirmer
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default Actions;
