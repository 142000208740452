import { useState, useEffect } from 'react';
import Logo1 from '../assets/images/logo1.svg';
import Logo2 from '../assets/images/logo2.svg';
import Logo3 from '../assets/images/logo3.svg';
import Logo4 from '../assets/images/logo4.svg';
import Logo5 from '../assets/images/logo5.svg';
import Logo6 from '../assets/images/logo6.svg';
import Logo7 from '../assets/images/logo7.svg';
import Logo8 from '../assets/images/logo8.svg';
import Logo9 from '../assets/images/logo9.svg';
import Logo10 from '../assets/images/logo10.svg';
import Logo11 from '../assets/images/logo11.svg';
import Logo12 from '../assets/images/logo12.svg';
import Logo13 from '../assets/images/logo13.svg';
import Logo14 from '../assets/images/logo14.svg';

const Partenaires = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const logos = [
    { id: 1, src: Logo1, alt: 'Logo 1' },
    { id: 2, src: Logo2, alt: 'Logo 2' },
    { id: 3, src: Logo3, alt: 'Logo 3' },
    { id: 4, src: Logo4, alt: 'Logo 4' },
    { id: 5, src: Logo5, alt: 'Logo 5' },
    { id: 6, src: Logo6, alt: 'Logo 6' },
    { id: 7, src: Logo7, alt: 'Logo 7' },
    { id: 8, src: Logo8, alt: 'Logo 8' },
    { id: 9, src: Logo9, alt: 'Logo 9' },
    { id: 10, src: Logo10, alt: 'Logo 10' },
    { id: 11, src: Logo11, alt: 'Logo 11' },
    { id: 12, src: Logo12, alt: 'Logo 12' },
    { id: 13, src: Logo13, alt: 'Logo 13' },
    { id: 14, src: Logo14, alt: 'Logo 14' },
  ];

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // First row
  const firstRow = isMobile ? logos.slice(0, 3) : logos.slice(0, 13);
  // Second row
  const secondRow = isMobile ? logos.slice(3, 7) : [];
  // Third row only visible on desktop
  const thirdRow = isMobile ? logos.slice(9, 14) : [];

  return (
    <div className="container mt-42 logo-pyramid">
      <h2 className='text-center bleu600'>Nos partenaires</h2>
      
      {/* First row */}
      <div className="d-flex flex-row justify-content-between">
        {firstRow.map((logo) => (
          <div className="col-md-auto col-sm" key={logo.id}>
            <img src={logo.src} alt={logo.alt} className="w-75 mr-8" />
          </div>
        ))}
      </div>

      {/* Second row */}
      <div className="d-flex flex-row justify-content-between">
        {secondRow.map((logo) => (
          <div className="col-md-3 col-sm" key={logo.id}>
            <img src={logo.src} alt={logo.alt} className="w-75 mr-8" />
          </div>
        ))}
      </div>

      {/* Third row (visible on desktop) */}
      {thirdRow.length > 0 && (
        <div className="d-flex flex-row justify-content-between">
          {thirdRow.map((logo) => (
            <div className="col-md-2 col-sm" key={logo.id}>
              <img src={logo.src} alt={logo.alt} className="w-75 mr-8" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Partenaires;
