import { Card, CardBody, CardImg } from "react-bootstrap";
import Image from "../assets/images/blog1.svg";
import PlayIcon from "../assets/images/controls-play.svg"
const ItemBlog = ({ data, blog }) => {
  function calculateReadingTime(htmlText) {
    const wordsPerMinute = 200; // Average reading speed
    const plainText = htmlText.replace(/<[^>]*>/g, ''); // Remove HTML tags
    
    const wordCount = plainText.trim().split(/\s+/).length; // Count words
    console.log({wordCount})
    const minutes = Math.ceil(wordCount / wordsPerMinute); // Round up to the nearest minute
    return `${minutes} min`;
  }
  
  
  
  return (
    <Card className="card-atchoum p-8">
      <div className="w-100">
       <CardImg src={data?.featured_image_url}  className="cover-image" alt="item img" /> 
      </div>
      

      <CardBody className="p-0 text-start">
        <p className="textBlog bleuM500 mt-16 min-h-56">{data?.titre} </p>
        <span className="timing mt-16"><img src={PlayIcon} alt="icon play" className="mr-8"/>{calculateReadingTime(data?.contenu)}</span>
      </CardBody>
    </Card>
  );
};
export default ItemBlog;
