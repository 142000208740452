import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import IconClose from '../assets/images/iconClose.svg';
import Form from 'react-bootstrap/Form';

function ModalType({ open, setOpen, type, setType, onConfirm }) {
  console?.log(type)
  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500">Quel justificatif d'identité ?</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>

      <Modal.Body>
        <p className='current-text'>Choisissez un document d'identité officiel à importer.</p>
        <Form>
          <div className="mb-3">
            <label className="d-flex align-items-center">
              <input
                type="radio" 
                id="carte-identite" 
                name="document-type"
                value="cin"
                checked={type === "cin"}
                onChange={(e) => setType(e.target.value)}
              />
              <span className="custom-radio"></span> Carte d'identité
            </label>
          </div>
          <div className="mb-3">
            <label className="d-flex align-items-center">
              <input
                type="radio" 
                id="passeport" 
                name="document-type"
                value="passeport"
                checked={type === "passeport"}
                onChange={(e) => setType(e.target.value)}
              />
              <span className="custom-radio"></span> Passeport
            </label>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button className="btn-primary-s p-16 w-100" onClick={onConfirm}>
          Suivant
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalType;
