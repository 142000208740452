import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Services from '../services/global.service';
import IconClose from '../assets/images/iconClose.svg';

function ModalRetraite({open,setOpen ,selectedRetraite,setSelectedRetraite}) {
   const [caisseRetraites, setCaisseRetraite] = useState([]);
   const getAllretraiteOptions=()=>{
    Services.getAll('caisses-retraite')
    .then((res)=>{
      console.log(res)
      setCaisseRetraite(res.data)
    })
    .catch((err)=>{
      console.log(err)
    })
   }
  const retraiteOptions = [
    "AG2R La Mondiale",
    "Harmonie Mutuelle",
    "Klesia",
    "Apicil",
    "Pro BTP",
    "Autre",
    "Ne sais pas ou ne souhaite pas répondre",
  ];
  useEffect(()=>{getAllretraiteOptions()},[])
  const handleConfirm = () => {
    //onSelectRetraite(selectedRetraite);
    setOpen(false); // Close the modal after selection
  };
  if (!open) return null;
  return (
    <Modal show={open} onHide={()=>{setOpen(false)}}>
    <Modal.Header className='border-0 d-flex justify-content-between align-items-start' >
      <h2 className='beluM500'>Cocher votre caisse de retraite</h2>
      <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
    </Modal.Header>
    <Modal.Body>
    <form>
            {retraiteOptions.map((option, index) => (
              <div>
              <label className="d-flex align-items-center">
                <input
                  type="radio"
                 // name="civilite"
                  name="retraite"
                    value={option}
                  checked={selectedRetraite === option}
                  onChange={(e) => setSelectedRetraite(e.target.value)}
                />
                <span className="custom-radio"></span> {option}
              </label>
            </div>
              
            ))}
          </form>
    </Modal.Body>
    <Modal.Footer>
      <button className="btn-primary-s w-100" 
      onClick={handleConfirm}
     // className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 rounded"
      disabled={!selectedRetraite} // Disable button until a selection is made
      >
        Je confirme
      </button>
     
    </Modal.Footer>
  </Modal>
  );
}

export default ModalRetraite;