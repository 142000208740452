import React, { useEffect, useState, useRef } from "react";
import ReturnIcon from "../../assets/images/return.svg";
import { useNavigate, useParams } from "react-router-dom";
import voirPlus from "../../assets/images/voirPlus.svg";
import AvatarGrp from "../../assets/images/AvatarGrp.svg";
import "../../assets/styles/messagerie.scss";
import DetailMessage from "./detailMessage";
import NewMessage from "./newMessage";
import Services from "../../services/global.service";
import PopUpConfirmation from "./popUpConfirmation";



function Discussion() {
  const navigate = useNavigate();
  const params = useParams();
  const [friend, setFriend] = useState();
  const [disc, setDisc] = useState();
  const [messages, setMessages] = useState([]);
  const [showPopUp, setShowPopUp] = useState(false);
  const messagesEndRef = useRef(null); // Ref for the messages container

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const now = new Date();
    const optionsDay = { weekday: "short", day: "numeric", month: "short" };
    const optionsTime = { hour: "2-digit", minute: "2-digit" };

    if (date.toDateString() === now.toDateString()) {
      return `${date.toLocaleTimeString("fr-FR", optionsTime)}`;
    } else {
      return `${date.toLocaleDateString("fr-FR", optionsDay)} - ${date.toLocaleTimeString("fr-FR", optionsTime)}`;
    }
  };

  const getDiscussion = () => {
    Services.getOne(params.idDiscussion, "messages/getDiscussions")
      .then((res) => {
        console.log(res?.data)
        setDisc(res?.data);
        if (res?.data?.typeGroupe === "prive") {
          setFriend(res?.data?.members[0]?.group_user);
        }
        const formattedData = res?.data?.discussion_group?.discussion_message.map((item) => ({
          content: item.message,
          sender: item?.sender,
          id:item?.id,
          deleted:item?.deleted_msg_sender,
          isMine: item?.sender_id == localStorage.getItem("userData"),
          timestamp: item?.created_at,
        }));
        console.log(formattedData)
        setMessages(formattedData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    getDiscussion();
  }, []);

  useEffect(() => {
    scrollToBottom(); // Scroll to the bottom when messages change
  }, [messages]);

  const onSend = (message) => {
    const newMessage = {
      content: message,
      isMine: true,
      timestamp: new Date(),
    };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    const data = {
      groupe_id: disc?.id,
      discussion_id: params.idDiscussion,
      message,
      ...(disc?.id ? {} : { user_id: [friend.id] }),
      ...(disc?.id ? {} : { groupe_name: "prive" }),
      ...(disc?.id ? {} : { typeGroupe: "amie" }),
    };
    Services.create(data, "messages/send")
      .then((res) => {
        console.log("RES", res);
        getDiscussion()
        // set last message ( add id)
        //setMessages((prevMessages) => [...prevMessages, newMessage]);
      })
      .catch((err) => {
        console.log(err);
        //tag msg not send or delete message
      });
  };

  return (
    <div className="container-fluid-lg p-16 bg-grey">
      <div className="col-lg-6 mx-auto">
      <PopUpConfirmation show={showPopUp} setShow={setShowPopUp} idDisc={disc?.discussion_group?.id} idUser={friend?.id}/>
      <div className="d-flex justify-content-between align-items-start">
        <button onClick={() => navigate(-1)} className="cta">
          <img src={ReturnIcon} className="mr-8" alt="return icon" />
        </button>
        <div className="text-center d-flex flex-column align-items-center">
        <div className="w75 text-center">
          {disc?.typeGroupe === "prive" ? (
            <img src={friend?.photo_profil} alt="photoContact" className="driver-icon " 
            style={{   
               width:" 100%",
              height: "100%",
              objectFit: "cover",}} />
          ) : (
            <img src={AvatarGrp} alt="photoContact" className="driver-icon w75" />
          )}
           </div>
          <p className="subtitle  text-centerbleuM500 mt-8">
            {disc?.typeGroupe === "prive" ? `${friend?.prenom} ${friend?.nom}` : disc?.groupe_name}
          </p>
          <div className=" text-center mt-8">
            <a href={disc?.typeGroupe === "prive" ? `/amis/${friend?.id}` : `/group/${disc?.id}`} className="cta">
              Voir le profil
            </a>
          </div>
        </div>
        <button className="cta" onClick={()=>{setShowPopUp(!showPopUp)}}>
          <img src={voirPlus} alt="voirPlus" />
        </button>
      </div>

      <div className="mt-19">
        {messages && messages?.map((msg, index) => (
          <React.Fragment key={index}>
            <p className="mt-16 color-time">{formatDate(msg?.timestamp)}</p>
            <DetailMessage setMessages={setMessages} isMine={msg?.isMine} id={msg?.id} text={msg?.deleted && msg?.sender?.id==localStorage.getItem('userData') ?'Message supprimé.':msg?.content} friendAvatar={msg?.sender?.photo_profil} />
          </React.Fragment>
        ))}
        {/* Invisible element to ensure scrolling works */}
        <div ref={messagesEndRef}></div>
      </div>

      <NewMessage placeholderText="Envoyez un message" onSend={onSend} />
      </div>
    </div>
  );
}

export default Discussion;
