import { useState, useEffect } from 'react';
import Rec1 from '../assets/images/rec1.svg';
import Rec2 from '../assets/images/rec2.svg';
import Rec3 from '../assets/images/rec3.svg';
import Rec4 from '../assets/images/rec4.svg';
import Rec5 from '../assets/images/rec5.svg';
import Rec6 from '../assets/images/rec6.svg';
import Rec7 from '../assets/images/rec7.svg';

const Recompense = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  const logos = [
    { id: 1, src: Rec1, alt: 'Logo 1' },
    { id: 2, src: Rec2, alt: 'Logo 2' },
    { id: 3, src: Rec3, alt: 'Logo 3' },
    { id: 4, src: Rec4, alt: 'Logo 4' },
    { id: 5, src: Rec5, alt: 'Logo 5' },
    { id: 6, src: Rec6, alt: 'Logo 6' },
    { id: 7, src: Rec7, alt: 'Logo 7' },
  ];

  // Handle window resize to update deviceWidth
  useEffect(() => {
    const handleResize = () => setDeviceWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Conditionally slice based on device width
  const visibleLogos = deviceWidth < 768 ? logos.slice(0, 3) : logos;

  return (
    <div className="container mt-42 logo-pyramid">
      <h2 className="text-center bleu600">Nos récompenses</h2>

      {/* Conditionally render logos based on device width */}
      <div className="d-flex flex-row justify-content-between">
        {visibleLogos.map((logo) => (
          <div className="col-auto col-xs-4" key={logo.id}>
            <img src={logo.src} alt={logo.alt} className="logo mr-8" />
          </div>
        ))}
      </div>

      {/* Only show the second row if device width is less than 768 */}
      {deviceWidth < 768 && (
        <div className="d-flex flex-row justify-content-between mt-16">
          {logos.slice(3, 7).map((logo) => (
            <div className="col-md-4 col-xs-3" key={logo.id}>
              <img src={logo.src} alt={logo.alt} className="logo mr-8" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Recompense;
