import { Modal } from "react-bootstrap";
import IconClose from "../assets/images/iconClose.svg";

const CibleModal = ({ open, setOpen, title, data, type, setSelectedItems, selectedItems }) => {
  console.log({ selectedItems });

  // Handle checkbox or radio button change
  const handleCheckboxChange = (e, el) => {
    const { checked } = e.target;

    // Define selected item based on type
    const selectedItem = type === "friend"
      ? { id: el?.ami?.id, name: `${el?.ami?.nom} ${el?.ami?.prenom}` }
      : { id: el?.id, name: el?.groupe_name };

    if (checked) {
      // Add the selected item (id and name)
      if(type==='friend'){
        setSelectedItems((prev) => [...prev, selectedItem]);
      }
      else{
        setSelectedItems([selectedItem])
      }
    } else {
      // Remove the deselected item (match by id)
      setSelectedItems((prev) => prev.filter((item) => item.id !== selectedItem.id));
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    console.log("Selected Items:", selectedItems); // Logs both id and name
    setOpen(false); // Close the modal
  };

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500">{title}</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>

      <Modal.Body>
        {/* Render checkboxes for friends */}
        {type === "friend" &&
          data.map((el, index) => (
            <div className="form-group text-start mt-16" key={index}>
              <label className="d-flex align-items-center current-text capitalise">
                <input
                  type="checkbox"
                  name="daycheck"
                  id={`day-${index}`}
                  value={el?.ami?.id}
                  checked={selectedItems.some((item) => item.id === el.ami?.id)} // Check if item is selected
                  onChange={(e) => handleCheckboxChange(e, el)} // Handle checkbox change
                />
                <span className="custom-checkbox"></span>
                {`${el?.ami?.nom} ${el?.ami?.prenom}`}
              </label>
            </div>
          ))}

        {/* Render radio buttons for groups */}
        {type === "group" &&
          data.map((el, index) => (
            <div className="mb-3" key={index}>
              <label className="d-flex align-items-center border-b pb-16">
                <input
                  type="radio"
                  id="group"
                  name="group"
                  value={el?.id}
                  checked={selectedItems.some((item) => item.id === el?.id)} // Check if item is selected
                  onChange={(e) => handleCheckboxChange(e, el)} // Handle radio button change
                />
                <span className="custom-radio"></span> {el?.groupe_name}
              </label>
            </div>
          ))}
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button className="btn-primary-s p-14 w-100" onClick={handleSubmit}>
          Valider
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default CibleModal;
