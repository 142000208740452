import Services from "../services/global.service";

export function checkToken() {
    const auth = localStorage.getItem("token");
  
    if (!auth) {
      return Promise.resolve(false); // No token, resolve as false
    }
  
    // Returning a promise to handle async validation in the component
    return Services.getAll("check-token")
      .then((res) => {
        if (res.data.status === "success") {
          return true; // Token is valid
        } else {
          localStorage.clear();
          return false; // Token invalid or expired
        }
      })
      .catch(() => {
        localStorage.clear();
        return false; // Token invalid or expired
      });
  }
  