import React, { useEffect, useState } from "react";
import Friend from "./friend";
import Search from "./search";
import AvatarFriend from "../../assets/images/conducteur.svg";
import ReturnIcon from "../../assets/images/return.svg";
import { useNavigate } from "react-router-dom";
import Services from "../../services/global.service";
import Header from "../../components/header";

function ListFriends() {
  const navigate = useNavigate();
  const [amis, setAmis] = useState([]);
  const [filteredAmis, setFilteredAmis] = useState([]);

  // Fetches the list of friends on component mount
  const getList = () => {
    Services.getAll('amis')
      .then((res) => {
        const data = Array.isArray(res?.data) ? res.data : [];
        setAmis(data);
        setFilteredAmis(data);
      })
      .catch((err) => {
        console.error("Error fetching friends:", err);
      });
  };

  // Handles search input to filter amis based on input value
  const onSearch = (value) => {
    const filtered = amis.filter((el) =>
      (el.ami.nom && el.ami.nom.toLowerCase().includes(value.toLowerCase())) ||
      (el.ami.prenom && el.ami.prenom.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredAmis(filtered); // Updates filtered list
  };

  // Reset the filtered amis to the full list
  const resetFiltered = () => {
    setFilteredAmis(amis);
  };

  // Fetch list only on initial mount
  useEffect(() => {
    getList();
  }, []);

  return (
    <>
    <div className="desktop">
        <Header connected={true} />
      </div>
      <div className="container-fluid-lg p-16 bg-grey">
        <div className="col-lg-6 mx-auto">
           <div className="d-flex justify-content-between align-items-start">
        <button onClick={() => navigate(-1)} className="cta">
          <img src={ReturnIcon} className="mr-8" alt="return icon" />
        </button>
      </div>
      <div>
        <Search
          placeholderText="Rechercher un ami(e)"
          onSearch={onSearch}
          resetFiltered={resetFiltered}
        />
        <p className="font-Livvic title-dis text-start mt-24">Suggestions</p>

        <div className="mt-16">
          {filteredAmis.map((el) => (
            <Friend
              key={el.ami.id}
              name={`${el.ami.nom} ${el.ami.prenom}`}
              avatar={el.ami.photo_profil && el.ami.photo_profil.length > 0 ? el.ami.photo_profil : AvatarFriend}
              id={el.ami.id}
              tag={false}
            />
          ))}
        </div>
      </div>
        </div>
     
    </div>
    </>
   
  );
}

export default ListFriends;
