// src/components/SpinLoader.jsx
import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

function SpinLoader({ color = "#439f8c", loading = true, size = 15, style }) {
  return (
    <ClipLoader
      color={color}
      loading={loading}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
      className="spinner"
      cssOverride={style}
    />
  );
}

export default SpinLoader;
