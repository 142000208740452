import Header from "../components/header";
import Layout from "./layout";

const Cgu = () => {
  return (
    <Layout>
        <div className="p-16">
        <div className=" text-start"><h1  class="bleuM500">Conditions Générales d’utilisation</h1>
	<div className=" ">
		<div className="">
			<p><strong>1. OBJET</strong></p>
<p>Atchoum est une plateforme de mise en relation entre habitants d’un territoire afin de partager des Trajets automobiles sur sa Plateforme ATCHOUM , composée d’un Site internet https://www.atchoum.eu/, d’une application mobile et d’un centre d’appels téléphonique, pour des Conducteurs se rendant d’un point de départ à une destination donnée et des Passagers qui se trouvent sur ce Trajet et souhaite aller d’un point de départ à un point d’arrivée se trouvant sur ce même Trajet . Cette Plateforme ATCHOUM est une Plateforme de covoiturage plutôt destinée aux courtes distances en territoire rural qu’ATCHOUM appelle courvoiturage© mais qui permet de pratiquer le covoiturage moyennes et longues distances.</p>
<p>Cette Plateforme ATCHOUM de mise en relation est conçue, développée et gérée par ATCHOUM SAS dont le siège social est situé 36 Route de Toulon 83136 MEOUNES LES MONTRIEUX, propriétaire de la marque ATCHOUM©. Dans le cadre du partage de Trajets, les frais y afférents sont partagés entre le ou les Passagers et le Conducteur du véhicule effectuant le Trajet dans le cadre et le respect des textes en vigueur dont quelques éléments sont rappelés ci-dessous&nbsp;:</p>
<p>Définition du covoiturage*</p>
<p>La loi du 17 août 2015 relative à la transition énergétique pour une croissance verte (TECV) définit le covoiturage (article L. 3132-1 du code des Transports) : «&nbsp;l’utilisation en commun d’un véhicule terrestre à moteur par un Conducteur et un ou plusieurs Passagers, effectuée à titre non onéreux, excepté le partage des frais, dans le cadre d’un déplacement que le Conducteur effectue pour son propre compte&nbsp;».</p>
<p>Prix du covoiturage</p>
<p>D’après l’administration fiscale, le coût du Trajet peut être évalué en utilisant le barème kilométrique (qui comprend notamment les frais de carburant, l’assurance et l’amortissement du véhicule) et en y ajoutant le prix des péages éventuels. Le Conducteur ne doit pas être en situation de bénéfice, et donc ne doit pas recevoir de la part de ses Passagers une somme supérieure au prix réel du Trajet fixé par le barème fiscal, soit environ 0,50 € du kilomètre selon les cylindrées + le péage. Sinon, il entre dans le champ du transport rémunéré de personnes, dont l’accès et l’exercice de la profession est réglementé, et est assujetti à la souscription d’une assurance professionnelle spécifique afin que ses Passagers soient couverts.</p>
<p>Pour calculer le montant du partage des coûts, la méthode suivante peut être appliquée :</p>
<p>– ne pas inclure dans le prix la Place du Conducteur qui s’assure du bon fonctionnement de son véhicule à l’année (assurance, entretien)</p>
<p>– se baser sur 3 Passagers par véhicule, et ainsi établir un prix par Passager = essence + péage divisé par trois</p>
<p>Assurance Conducteur *</p>
<p>Le covoiturage est une offre désintéressée de service de transport, dont l’éventuelle contrepartie financière est limitée au partage des frais (carburant, péage par exemple). Si un membre de la plateforme ATCHOUM propose un covoiturage en tant que Conducteur, il doit souscrire au minimum une assurance responsabilité civile. Cette garantie couvre les dommages qui peuvent être occasionnés à des tiers lors d’un sinistre et le Passager du covoiturage est aussi couvert par cette assurance obligatoire. Certaines situations peuvent cependant être exclues de la couverture offerte par cette garantie comme le prêt de volant (franchise). Nous préconisons aux Conducteurs de contacter leur assureur pour vérification de la prise en charge.</p>
<p>Assurance Passager *</p>
<p>En tant que Passager, celui-ci est couvert par la garantie « responsabilité civile », qui est automatiquement incluse dans tous les contrats d’assurance automobile souscrits par les particuliers. En cas d’accident, si le passager est blessé, c’est l’assurance du Conducteur responsable de l’accident qui prendra en charge l’indemnisation de la totalité de ses dommages corporels, aucune franchise ne pouvant être laissée à sa charge.</p>
<p>Exonération d’impôt des revenus tirés du covoiturage*</p>
<p>Les conditions d’exonération d’impôt des revenus tirés du covoiturage ont été précisées par une circulaire fiscale en date du 30 août 2016. Les revenus encaissés dans le cadre d’un covoiturage sont exonérés d’impôt sur le revenu et n’ont pas à être déclarés dès lors qu’il y a participation du contribuable en tant que Conducteur. Mais, le revenu encaissé ne doit pas dépasser le montant total des frais liés au Trajet, déduction faite de la quote-part du Conducteur.</p>
<p>(* source&nbsp;: ministère de la transition écologique et solidaire)</p>
<p>Les présentes conditions générales d’utilisation ont pour objet d’encadrer l’accès et les modalités d’utilisation de la Plateforme ATCHOUM et chaque membre inscrit est réputé les avoir lues et acceptées pour être membre. En les lisant chaque membre est réputé comprendre et accepter qu’ATCHOUM n’est partie prenante à aucun accord, contrat ou relation contractuelle, de quelque nature que ce soit, conclu entre les Membres inscrits sur sa Plateforme ATCHOUM en tant que passagers et/ou conducteurs.</p>
<p>Le fait de s’inscrire sur la Plateforme ATCHOUM emporte la reconnaissance par chaque membre inscrit d’avoir pris connaissance des présentes conditions générales d’utilisation et de les accepter c’est-à-dire qu’elles s’imposent à lui de manière globale et pour tous ses composantes.</p>
<p><strong>2. DE QUOI PARLE-T-ON&nbsp;SUR LA PLATEFORME ATCHOUM ?</strong></p>
<p>Ci-dessous les principales définitions des termes employés sur la plateforme ATCHOUM&nbsp;:</p>
<p>« CGU »&nbsp;: désigne les présentes Conditions Générales d’Utilisation</p>
<p>«&nbsp;Collectivité&nbsp;» désigne la Collectivité qui a souscrit l’abonnement au centre d’appels téléphoniques qui ouvre le droit pour les habitants de son territoire et dans le cadre de sa convention d’abonnement au centre d’appels téléphoniques avec ATCHOUM SAS, de faire bénéficier ceux-ci des Fonctionnalités de son Site internet, de son application mobile et de son centre d’appels.</p>
<p>« Conducteur » désigne le Membre proposant, sur la Plateforme ATCHOUM, de faire profiter un autre Membre (des autres Membre s) d’un ou plusieurs de ses Trajet s dans le cadre d’une participation aux frais.</p>
<p>«&nbsp;Confirmation de Trajet »&nbsp;: désigne le message sous forme de mail et /ou SMS et/ ou appel téléphonique envoyé aux Membres dès qu’ils ont décidé et accepté de covoiturer ensemble par l’intermédiaire de la plateforme ATCHOUM.</p>
<p>«&nbsp; Fonctionnalités » désigne les interactions entre Membres de la plateforme et de ceux-ci avec ATCHOUM</p>
<p>«&nbsp;Frais de gestion » ont la signification donnée à l’article 5.2.</p>
<p>«&nbsp;Inscription &nbsp;» désigne le fait de fournir les renseignements demandés avec prise de connaissance et acceptation pleine et entière des présentes conditions générales pour pouvoir utiliser la plateforme</p>
<p>« Membre » désigne toute personne physique qui s’est inscrit sur la Plateforme ATCHOUM.</p>
<p>«&nbsp;Organisme &nbsp;» désigne l’Organisme qui a souscrit l’abonnement au centre d’appels téléphoniques qui ouvre le droit pour ses ressortissants et dans le cadre de sa convention d’abonnement au centre d’appels avec ATCHOUM SAS, de faire bénéficier ceux-ci des Fonctionnalités de son Site internet, de son application mobile et de son centre d’appels.</p>
<p>« Participation aux Frais » désigne, pour un Trajet en Covoiturage donné, complet ou tronçon, la somme d’argent demandée par le Conducteur et acceptée par le Passager au titre de sa Participation aux Frais de trajet.</p>
<p>« Passager » désigne le Membre ayant accepté la proposition d’être transporté par le Conducteur ou, le cas échéant, la personne pour le compte de laquelle un Membre a réservé une Place</p>
<p>« Place » désigne la Place réservée par un Passager à bord du véhicule d’un Conducteur pour un Trajet donné.</p>
<p>«&nbsp;Plateforme&nbsp;»&nbsp;: désigne l’ensemble site internet, application mobile, centre d’appels téléphonique, base de données, fonctionnalités produites par les logiciels, algorithmes et intelligence artificielle et les contenus qui y sont publiés par les membres et par ATCHOUM.</p>
<p>« Site » désigne le Site internet accessible à l’adresse www.atchoum.eu</p>
<p>«&nbsp;Ticket trajet&nbsp;»&nbsp;: c’est un moyen de paiement mis en place pour les membres qui ne peuvent ou ne veulent pas utiliser la carte bancaire ou le prélèvement automatique SEPA. Les tickets trajet sont numérotés afin de ne pouvoir être utilisés qu’une seule fois.</p>
<p>«&nbsp;Trajet »&nbsp;: désigne le parcours effectué par un Conducteur ou un Passager pour se rendre d’un point de départ et un point d’arrivé fixé par chacun d’eux. «&nbsp;Trajet » désigne indistinctement un Trajet en Covoiturage ou un segment d’un Trajet.</p>
<p>« Trajet en Covoiturage » désigne le Trajet faisant l’objet d’une Annonce de Trajet publiée par un Conducteur sur la Plateforme ATCHOUM et pour lequel il accepte de transporter des Passager s en contrepartie de la Participation aux Frais, que ce soit un Trajet complet ou un tronçon de Trajet comme défini à «&nbsp;Trajet&nbsp;».</p>
<p><strong>3. INSCRIPTION A LA PLATEFORME ATCHOUM ET CREATION DE SON COMPTE</strong></p>
<p>3.1 Conditions d’Inscription à la Plateforme ATCHOUM</p>
<p>L’utilisation de Plateforme ATCHOUM est réservée aux personnes physiques âgées de 18 ans ou plus et toute Inscription sur la Plateforme ATCHOUM par une personne mineure est strictement interdite sauf autorisation expresse des parents ou représentant légal à transmettre à ATCHOUM.</p>
<p>En accédant, utilisant ou en s’inscrivant sur la Plateforme ATCHOUM, toute personne le faisant déclare et garanti avoir 18 ans ou plus.</p>
<p>3.2 Inscription</p>
<p>La Plateforme ATCHOUM permet aux Membres de publier des Annonces de Trajet s et/ou de consulter des Annonces de Trajet, de formuler des souhaits de Trajet(s) ainsi que d’interagir entre eux pour la réservation de Place(s). Les offres de Trajets sont consultables même sans être inscrit sur la Plateforme ATCHOUM. Par contre pour publier une offre de Trajet et / ou réserver une place sur un Trajet il faut au préalable s’inscrire pour devenir Membre de la plateforme ATCHOUM.</p>
<p>Pour s’inscrire sur la plateforme il faut remplir l’ensemble des champs obligatoires figurant sur le formulaire d’Inscription en ligne ou fournir les informations au centre d’appels téléphoniques qui le fera en lieu et place de l’appelant. A cette occasion le centre d’appels proposera l’envoi postal des présentes CGU à l’appelant qui en retournera un exemplaire signé à l’adresse indiquée.</p>
<p>Pour s’inscrire sur la Plateforme ATCHOUM, il faut avoir lu et accepter les présentes CGU, la charte des utilisateurs ainsi que la Politique de Confidentialité. En cas de contestation suite à une inscription par téléphone, ni ATCHOUM ni l’appelant ne pourront opposer les présentes CGU en cas de litige entre eux si l’exemplaire signé des présentes CGU n’est pas retourné à ATCHOUM à l’adresse de retour indiquée après une inscription par téléphone.</p>
<p>L’inscription sur la plateforme ATCHOUM engage celui qui s’inscrit à fournir des informations personnelles exactes et conformes à la réalité et à les mettre à jour, par l’intermédiaire de son profil ou en en avertissant ATCHOUM.</p>
<p>Par ailleurs l’inscription engage la personne qui le fait à garder secret le mot de passe choisi lors de son inscription et à ne le communiquer à personne. En cas de perte ou divulgation du mot de passe la personne qui l’a créé s’engage à en informer sans délai ATCHOUM. Chaque utilisateur est seul responsable de l’utilisation faite par un tiers de la plateforme ATCHOUM, tant qu’il n’a pas expressément notifié à ATCHOUM la perte, l’utilisation frauduleuse par un tiers ou la divulgation de son mot de passe à un tiers.</p>
<p>Chaque utilisateur s’engage à s’inscrire ou utiliser, sous sa propre identité ou celle d’un tiers, qu’une seule fois hormis les cas nécessitant une réinscription et demandés par ATCHOUM suite à des problèmes techniques nécessitant cette réinscription.</p>
<p>3.3 Vérification</p>
<p>A des fins de transparence, d’amélioration de la confiance, de prévention ou détection des fraudes, ATCHOUM peut mettre en place un système de vérification de certaines des informations fournies sur les profils et pour les Inscriptions, notamment: numéro de téléphone, pièce d’identité, carte grise, attestation d’assurance, contrôle technique à jour.</p>
<p>Les informations dites «&nbsp;vérifiées&nbsp;» sur la plateforme ATCHOUM signifient seulement que les informations et document demandés ont été fournis lors de l’inscription par chaque utilisateur afin de permettre de fournir toutes les informations le concernant à un autre inscrit avec lequel il envisage de faire un trajet. ATCHOUM ne garantit ni la véracité, ni la fiabilité, ni la validité des informations fournies lors de l’inscription d’un membre de la plateforme compte tenu des délais entre la fourniture de celles-ci et leur validité.</p>
<p><strong>4. UTILISATION DES FONCTIONNALITES DE LA PLATEFORME ATCHOUM</strong></p>
<p>4.1 Publication des Annonces de Trajet</p>
<p>En tant que Membre, et sous réserve de remplir les conditions ci-dessous, il est possible de créer et publier des Annonces de Trajet sur la Plateforme ATCHOUM en indiquant des informations quant au Trajet qu’il est prévu d’effectuer (dates/heures et lieux de départ et d’arrivée, nombre de Places offertes, options proposées, montant de la Participation aux Frais, etc.).</p>
<p>Lors de la publication des Annonce de Trajet, il est possible d’indiquer une ou des étapes, auxquelles le Conducteur accepte de s’arrêter pour prendre ou déposer des Passagers. Les segments du Trajet en Covoiturage entre ces villes étapes ou entre l’une de ces villes étapes et le point de départ ou d’arrivée du Trajet en Covoiturage constituent également des «Trajets ».</p>
<p>Il n’est autorisé de publier une Annonce de Trajet sur la Plateforme ATCHOUM que si la Charte Utilisateurs ATCHOUM, la politique de confidentialité ainsi que les présentes Conditions Générales d’Utilisation ont été expressément lues et approuvées.</p>
<p>Chaque Membre de la Plateforme ATCHOUM reconnaît être le seul responsable du contenu de(s) Annonce(s) de Trajet qu’il publie sur la Plateforme ATCHOUM. En conséquence, il déclare et garanti l’exactitude et la véracité de toute information contenue dans son(ses) Annonce(s) de Trajet et s’engage à effectuer le(s) Trajet (s) proposés selon les modalités décrites dans son(ses) Annonces de Trajet .</p>
<p>Toute offre de Trajet sera publiée sur la Plateforme ATCHOUM et donc visible des Membres et de tous visiteurs, même non Membre, effectuant une recherche sur la Plateforme ATCHOUM ou sur le Site internet des partenaires de ATCHOUM.</p>
<p>Atchoum se réserve la possibilité, à sa seule discrétion et sans préavis, de ne pas publier ou retirer, à tout moment, toute Annonce de Trajet qui ne serait pas conforme aux CGU et à la Charte Utilisateurs ATCHOUM ou qui serait considérée comme préjudiciable à son image et celle de la Plateforme ATCHOUM et de ses fonctionnalités.</p>
<p>Tout Membre qui utiliserait la Plateforme ATCHOUM en tant que professionnel et non pas dans les règles du covoiturage entre particuliers s’exposerait aux sanctions prévues à l’article L.132-2 du Code de la consommation.</p>
<p>4.2. Réservation d’une Place</p>
<p>La plateforme Atchoum offre un système de réservation de Places par son Site internet, son application mobile et son centre d’appels téléphoniques pour les Trajets proposés sur la Plateforme ATCHOUM.</p>
<p>4.2.1 Trajet</p>
<p>Lorsqu’un Membre est intéressé par une Annonce de Trajet, il peut effectuer une demande de Réservation en ligne ou par le centre d’appel s’il ne peut le faire en ligne. Cette demande de Réservation est acceptée manuellement par le Conducteur. Au moment de la Réservation, le Passager procède au paiement en ligne, ou par ticket ATCHOUM dans le cas d’une réservation par le centre d’appels, du montant de la Participation aux Frais et des Frais de gestion afférents, le cas échéant. Après vérification du paiement par ATCHOUM et validation de la demande de Réservation par le Conducteur, le Passager et le Conducteur reçoive une notification de réservation (la « Confirmation de Trajet ») sur leur espace client dans la Plateforme ATCHOUM et par email et/ou par téléphone.</p>
<p>Cette «&nbsp;Confirmation de Trajet » comprend les numéros de téléphone Conducteur et Passager(s). A compter de la Confirmation de Trajet, Passagers et Conducteurs sont seuls responsables de l’exécution du contrat de Trajet les liant et conclu par l’intermédiaire de la plateforme ATCHOUM.</p>
<p>4.2.2 Caractère nominatif de la réservation de Place (s) sur un Trajet et modalités d’utilisation des Fonctionnalités de la plateforme ATCHOUM pour le compte d’un tiers</p>
<p>Toute utilisation des Fonctionnalités de la plateforme ATCHOUM, que ce soit en qualité de Passager ou de Conducteur, est nominative. Le Conducteur comme le Passager doivent correspondre à l’identité communiquée à ATCHOUM et aux autres Membres participant au(x) Trajet (s) de Covoiturage.</p>
<p>Toutefois ATCHOUM permet à ses Membres de réserver une ou plusieurs Places pour le compte d’un tiers. Dans ce cas, le ou les Membres s’engagent à indiquer avec exactitude au Conducteur, au moment de la Réservation de Place (s), les prénoms, âge et numéro de téléphone de la personne pour le compte de laquelle il(s) réserve(nt) une Place. La réservation de Place pour un mineur tiers de moins de 18 est interdite sauf fourniture au Conducteur d’une autorisation des parents ou représentants légaux dîment remplie et signée.</p>
<p>La plateforme ATCHOUM est destinée à la réservation de Place(s) pour des Trajet s de personnes et donc il ne peut être réservé de Place(s) pour d’autres usages tels que transport d’objet, colis, matériels, animaux voyageant seuls.</p>
<p>Les Conducteurs ne sont autorisés à ne publier que des offres de Trajet pour eux même avec leur propre véhicule répondant aux critères légaux des véhicules autorisés pour le covoiturage.</p>
<p>4.2.3 Modération</p>
<p>Vous reconnaissez et acceptez qu’ATCHOUM se réserve la possibilité de ne pas publier ou supprimer toute question, tout commentaire ou toute réponse dont ATCHOUM jugerait le contenu contraire aux présentes CGU.</p>
<p><strong>5. Conditions financières</strong></p>
<p>L’accès et l’Inscription à la Plateforme ATCHOUM de même que la recherche, la consultation et la publication de Trajets sont gratuits. La fonctionnalité de mise en relation c’est-à-dire la conclusion d’une Réservation est payante dans les conditions décrites ci-dessous.</p>
<p>5.1. Participation aux Frais et Prix</p>
<p>5.1.1. Lorsqu’un Conducteur publie une Annonce de Trajet, l’algorithme de la plateforme ATCHOUM suggère un montant de Participation aux Frais pour ce Trajet en Covoiturage ou un segment de ce Trajet en fonction d’une demande d’un Membre sur le Trajet.</p>
<p>Le montant de la Participation aux Frais est déterminé par l’algorithme de la plateforme ATCHOUM en fonction de la distance du Trajet choisi par le(s) Membre(s) et des règles de Participation aux Frais supportés réellement par le Conducteur et telles que définies au paragraphe «&nbsp;1. OBJET&nbsp;» qui tient compte notamment de la nature du Trajet et de la distance parcourue. Ce montant est purement indicatif et il appartient à chaque Conducteur de le modifier à la hausse ou à la baisse pour tenir compte des frais qu’il supporte réellement sur le Trajet proposé.</p>
<p>Le Conducteur qui accepte une réservation de Place pour un Trajet qu’il propose est réputé accepter pleinement et entièrement le montant de la Participation aux Frais qui est calculée suivant ces modalités sauf à l’avoir modifié dans le cadre des possibilités d’ajustement offertes.</p>
<p>Afin d’éviter les abus, ATCHOUM limite les possibilités d’ajustement du montant de la Participation aux Frais et pour respecter la réglementation du covoiturage.</p>
<p>5.2. Frais de Gestion</p>
<p>Dans le cadre des Trajets ATCHOUM prélève, en contrepartie de l’utilisation de la Plateforme ATCHOUM, au moment de la Confirmation de trajet, des «&nbsp;Frais de gestion&nbsp;» calculés sur la base du montant de la Participation aux Frais. Les modalités de calcul des Frais de gestion sont 0.30 € TTC forfaitaire pour les trajets inférieurs ou égaux à 5 kms et 12 % du montant de la participation aux frais au-delà de 5 kilomètres, ce montant étant TTC.</p>
<p>Les Frais de gestion sont perçus par ATCHOUM pour chaque Place faisant l’objet d’une Confirmation de trajet par un Conducteur.</p>
<p>5.3. Arrondis</p>
<p>Chaque membre reconnait et accepte qu’Atchoum puisse, à son entière discrétion, arrondir au chiffre supérieur en centième d’euros les Frais de gestion et la Participation aux Frais.</p>
<p>5.4. Modalités de paiement et de reversement de la Participation aux Frais au Conducteur</p>
<p>5.4.1. Mandat d’encaissement</p>
<p>En utilisant la Plateforme ATCHOUM en tant que Conducteur pour des Trajets avec Réservation, chaque Conducteur confie expressément à ATCHOUM, et pour chaque trajet, un mandat d’encaissement du montant de la Participation aux Frais en son nom et pour son compte.</p>
<p>Par conséquent, dans le cadre d’un Trajet en Covoiturage, et après acceptation de la Réservation de Trajet, ATCHOUM encaisse la totalité de la somme versée par le Passager (Participation aux Frais et Frais de gestion).</p>
<p>Les Participations aux Frais reçues par ATCHOUM sont déposées sur un compte dédié au paiement des Conducteurs dans la comptabilité d’Atchoum.</p>
<p>Chaque Conducteur reconnait et accepte qu’aucune des sommes perçues par ATCHOUM en son nom et pour son compte n’emporte droit à intérêts. Chaque Conducteur accepte de répondre avec diligences à toute demande d’ATCHOUM et plus généralement de toute autorité administrative ou judiciaire compétente en particulier en matière de prévention ou la lutte contre le blanchiment. Notamment, il accepte de fournir, sur simple demande, tout justificatif d’adresse et/ou d’identité utile et toutes autres informations qui seraient nécessaires.</p>
<p>En l’absence de réponse de la part d’un Conducteur à ces demandes, ATCHOUM pourra prendre toute mesure qui lui semblera appropriée notamment le gel des sommes versées et/ou la suspension de son Compte.</p>
<p>5.4.2. Modalités de paiement</p>
<p>A la suite de la confirmation de Trajet, ATCHOUM considère la confirmation&nbsp;du Trajet comme acquise.</p>
<p>ATCHOUM encaisse alors le montant du Trajet tel que calculé et suggéré par l’algorithme de la plateforme ATCHOUM, ou modifié par le Conducteur dans les règles d’ajustements et accepté par le Passager, soit au moyen d’un paiement par carte bancaire ou par prélèvement SEPA.</p>
<p>Une modalité de paiement par Ticket ATCHOUM permet aux personnes qui ne peuvent ou ne veulent utiliser le paiement par carte bancaire ou prélèvement SEPA de s’acquitter du montant du coût du trajet calculé par l’algorithme de la plateforme ATCHOUM. Les Tickets ATCHOUM sont disponibles à l’achat dans les lieux indiqués sur la plateforme ATCHOUM en fonction du lieu de résidence du passager. Chaque Ticket ATCHOUM à une valeur de 1.25 €. Dans le cas de paiement par Ticket ATCHOUM, le montant à payer sera celui calculé par l’algorithme de la plateforme ATCHOUM, ou révisé par le conducteur, divisé par 1.25 € et arrondi au Ticket ATCHOUM entier supérieur et indiqué soit sur la plateforme ATCHOUM soit par le centre d’appels. Par exemple pour un trajet de de 18 km le montant suggéré est de 7.03 €, le passager devra donner 7.03 / 1.25 = 5.62 Tickets ATCHOUM arrondi à 6 Tickets ATCHOUM au conducteur soit 7.50 €. Ce mode de calcul tient compte de l’impossibilité de disposer de Tickets ATCHOUM correspondant exactement au montant de chaque trajet possible et des frais de gestion spécifiques de ce mode de paiement. Ces Tickets ATCHOUM sont numérotés pour éviter les fraudes. Tout utilisateur utilisant ce mode de paiement est réputé accepter ces modalités.</p>
<p>5.43 Versement de la Participation aux Frais au Conducteur</p>
<p>A compter de cette confirmation et après réalisation du Trajet, chaque Conducteur dispose d’un crédit exigible sur son Compte. Ce crédit correspond au montant total payé par le Passager au moment de la confirmation de la Réservation diminué des Frais de gestion, c’est-à-dire le montant de la Participation aux Frais payée par le Passager. La somme correspondante à ce crédit sera virée sur le compte bancaire de chaque Conducteur tel qu’il en aura renseigné les coordonnées au moment de son Inscription en tant que Membre sous un délai de 7 jours maximum.</p>
<p>Concernant les Tickets ATCHOUM les conducteurs inscrits sur la plateforme sont réputés accepter ce mode de paiement sauf refus express de leur part lors de leur inscription. Dans ce cas le passager remettra au Conducteur le nombre de Tickets ATCHOUM indiqué lors de sa réservation. Le conducteur qui a encaissé ces Tickets ATCHOUM pour le paiement d’un trajet doit les transmettre à l’adresse indiquée au verso de chaque Tickets ATCHOUM pour être crédité du montant de la participation aux frais de trajets correspondante sur son compte bancaire dans le cadre des modalités décrites ci-dessus.</p>
<p><strong>6. Finalité non commerciale et non professionnelle de l’utilisation par des Conducteurs des Fonctionnalités et de la Plateforme ATCHOUM</strong></p>
<p>Chaque Conducteur inscrit et Membre s’engage à n’utiliser les Fonctionnalités et la Plateforme ATCHOUM que pour être mis en relation, à titre non professionnel et non commercial, avec des personnes souhaitant partager un Trajet en Covoiturage avec lui.</p>
<p>Chaque Conducteur inscrit et Membre s’engage à ne pas demander une Participation aux Frais supérieure aux frais supportés réellement et susceptible de faire générer un bénéfice, étant précisé que s’agissant d’un partage de frais, chaque Conducteur devra également, en tant que Conducteur , supporter sa part des coûts afférents au Trajet en Covoiturage. Chaque Conducteur inscrit et Membre est seul responsable d’effectuer le calcul des frais supportés pour chaque Trajet en Covoiturage, et de s’assurer que le montant demandé à ses Passagers n’excède pas les frais supportés réellement (en excluant sa part de participation aux frais), notamment en se référant au barème fiscal kilométrique forfaitaire applicable.</p>
<p>ATCHOUM se réserve la possibilité de suspendre un Membre dans le cas où, en tant que Conducteur, il utiliserait un véhicule professionnel de type VTC ou taxi, une voiture de fonction ou de service et générerait de ce fait un bénéfice à partir de l’utilisation de la Plateforme ATCHOUM pour organiser des covoiturages dans ces conditions. Chaque Conducteur s’engage à fournir à ATCHOUM, sur simple demande de sa part, une copie de sa carte grise et/ou tout autre document de nature à attester que son véhicule est conforme aux exigences du covoiturage, qu’il est autorisé à utiliser ce véhicule sur la Plateforme ATCHOUM et qu’il n’en tirera aucun bénéfice.</p>
<p>ATCHOUM se réserve également la possibilité de suspendre le Membre, ou limiter l’accès aux Fonctionnalités en cas d’activité de la part d’un Conducteur sur la Plateforme ATCHOUM , qui, du fait de la nature des Trajets proposés, de leur fréquence, du nombre de Passagers transportés ou du montant de Participations aux Frais demandées, entrainerait une situation de bénéfice pour lui ou pour quelque raison que ce soit faisant suspecter à ATCHOUM qu’il génère un bénéfice à partir de l’utilisation de la Plateforme ATCHOUM .</p>
<p><strong>7. Politique d’annulation</strong></p>
<p>7.1. Modalités de remboursement en cas d’annulation</p>
<p>L’annulation d’une Place d’un Trajet en Covoiturage par le Conducteur ou le Passager après la Confirmation de Réservation du Conducteur sera soumise aux conditions suivantes :</p>
<p>– En cas d’annulation imputable au Conducteur, le(s) Passager(s) ne sera (seront) pas débités de la Participation aux Frais de Trajet et les Frais de Gestion afférents sont conservés par ATCHOUM. C’est notamment le cas lorsque le Conducteur annule un Trajet en Covoiturage ou ne se rend pas au point de rendez-vous au plus tard 15 minutes après l’horaire convenu.</p>
<p>– En cas d’annulation imputable au Passager :</p>
<p>Si le Passager annule plus de 12 heure avant l’heure prévue pour le départ telle que mentionnée dans l’Annonce de Covoiturage, le Passager ne sera pas débité du montant de la Participation aux Frais. Les Frais de gestion demeurant acquis à ATCHOUM et le Conducteur ne recevant aucune somme de quelque nature que ce soit.</p>
<p>Si le Passager annule entre 12 heures et moins de 1 heures avant l’heure prévue pour le départ, telle que mentionnée dans l’Annonce de Covoiturage et après la Confirmation de Trajet, le Passager sera débité à hauteur de la moitié de la Participation aux Frais versée lors de la Réservation, les Frais de gestion demeurant acquis à ATCHOUM et le Conducteur recevant 50% de la Participation aux Frais.</p>
<p>Si le Passager annule entre 1 heure avant le départ prévu et l’heure de départ prévue telle que mentionnée dans l’Annonce, ou s’il ne se présente pas au lieu de rendez-vous au plus tard dans un délai de 15 minutes à compter de l’heure convenue, il sera débité de la totalité des frais de participation au Trajet et des frais de gestion y afférents. Le Conducteur sera dédommagé à hauteur de la totalité de la Participation aux Frais et les Frais de gestion seront conservés par ATCHOUM.</p>
<p>Lorsque l’annulation intervient avant le départ et du fait du Passager, la ou les Places annulée(s) par le Passager pour ledit Trajet en Covoiturage sont de plein droit remises à la disposition d’autres Passagers pouvant les réserver en ligne ou par le centre d’appels et en conséquence soumises aux conditions des présentes CGU.</p>
<p>ATCHOUM apprécie à sa seule discrétion, sur la base des éléments à sa disposition et fournis, la légitimité des demandes de remboursement qu’elle reçoit.</p>
<p>7.2. Droit de rétraction</p>
<p>En acceptant les présentes CGU, chaque membre accepte expressément que le Contrat entre lui et ATCHOUM consistant en la mise en relation avec un autre Membre soit exécuté avant l’expiration du délai de rétractation dès la Confirmation de Trajet et renonce expressément à son droit de rétraction, conformément aux dispositions de l’article L.221-28 du Code de la consommation.</p>
<p><strong>8. Comportement des utilisateurs de la Plateforme ATCHOUM et Membre s</strong></p>
<p>8.1. Engagement de tous les utilisateurs de la Plateforme ATCHOUM</p>
<p>Chaque membre reconnait être seul responsable du respect de l’ensemble des lois, règlements et obligations applicables à son utilisation de la Plateforme ATCHOUM et à l’utiliser dans le cadre de la charte des utilisateurs de la plateforme ATCHOUM qu’il a lue et acceptée par ailleurs pour être Membre.</p>
<p><strong>9. Suspension de comptes, limitation d’accès et résiliation</strong></p>
<p>Chaque Membre a la possibilité de mettre fin à sa relation contractuelle avec ATCHOUM à tout moment, sans frais et sans motif. Pour cela, il lui suffit de se rendre dans l’onglet « Fermeture de compte » de sa page Profil de Membre.</p>
<p>En cas de violation de la part d’un Membre des présentes CGU , notamment de ses obligations en tant que Membre mentionnées aux articles 6 et 8 ci-dessus, de dépassement du seuil visé à l’article 5.1 ci-dessus ou si ATCHOUM a des raisons sérieuses de croire que ceci est nécessaire pour protéger sa sécurité et son intégrité, celles de ses Membres ou de tiers ou à des fins de prévention des fraudes ou d’enquêtes, ATCHOUM se réserve la possibilité de :</p>
<p>résilier, immédiatement et sans préavis, les présentes CGU.</p>
<p>et/ou empêcher la publication ou supprimer tout avis, Annonce, messages, contenus, demande de Réservation, ou tout contenu publié par ce Membre sur la Plateforme ATCHOUM.</p>
<p>et/ou limiter l’accès et l’utilisation de la Plateforme ATCHOUM à ce Membre.</p>
<p>et/ou suspendre de façon temporaire ou permanente ce Membre.</p>
<p>Lorsque cela est nécessaire, le Membre sera notifié de la mise en Place d’une telle mesure afin de lui permettre de donner des explications à ATCHOUM. ATCHOUM décidera, à sa seule discrétion, de lever ou non les mesures mises en place.</p>
<p><strong>10. Données personnelles</strong></p>
<p>Dans le cadre de l’utilisation de la Plateforme ATCHOUM, ATCHOUM est amenée à collecter et traiter certaines données personnelles. En utilisant la Plateforme ATCHOUM et s’inscrivant en tant que Membre, chaque Membre reconnait et accepte le traitement de ses données personnelles collectées par ATCHOUM conformément à la loi applicable et aux stipulations de la Politique de Confidentialité dans le cadre de la Réglementation Générale de la Protection des Données, qu’il est réputé après lues et acceptées lors de son Inscription.</p>
<p><strong>11. Propriété intellectuelle</strong></p>
<p>11.1. Contenu publié par ATCHOUM</p>
<p>Sous réserve des contenus fournis par ses Membres, ATCHOUM est seul propriétaire de l’ensemble des droits de propriété intellectuelle afférents aux Fonctionnalités de la Plateforme ATCHOUM, à son contenu (notamment les textes, images, dessins, logos, vidéos, sons, données, graphiques) ainsi qu’aux logiciels, algorithme et bases de données assurant leur fonctionnement.</p>
<p>ATCHOUM accorde à chaque Membre inscrit une licence non exclusive, personnelle et non cessible d’utilisation de la Plateforme ATCHOUM et de ses Fonctionnalités, pour son usage personnel et privé, à titre non commercial et conformément aux finalités de la Plateforme ATCHOUM et des Fonctionnalités.</p>
<p>Chaque Membre de la plateforme ATCHOUM s’interdit toute autre utilisation ou exploitation de la Plateforme ATCHOUM et des Fonctionnalités, et de leur contenu sans l’autorisation préalable écrite de ATCHOUM. Notamment, il s’interdit de :</p>
<p>reproduire, modifier, adapter, distribuer, représenter publiquement, diffuser la Plateforme ATCHOUM, les Fonctionnalités et leur contenu, à l’exception de ce qui est expressément autorisé par ATCHOUM.</p>
<p>décompiler, procéder à de l’ingénierie inverse de la Plateforme ATCHOUM ou des Fonctionnalités, sous réserve des exceptions prévues par les textes en vigueur.</p>
<p>extraire ou tenter d’extraire (notamment en utilisant des robots d’aspiration de données ou tout autre outil similaire de collecte de données) une partie substantielle des données de la Plateforme ATCHOUM.</p>
<p>11.2. Contenu publié par vous sur la Plateforme ATCHOUM</p>
<p>Afin de permettre la fourniture des Fonctionnalités et conformément à la finalité de la Plateforme ATCHOUM, chaque Membre inscrit sur la plateforme ATCHOUM concède à ATCHOUM une licence non exclusive d’utilisation des contenus et données qu’il fournit dans le cadre de son utilisation des Fonctionnalités (ci-après, votre « Contenu Membre »).</p>
<p>Afin de permettre à ATCHOUM la diffusion par réseau numérique et selon tout protocole de communication, (notamment Internet et réseau mobile), ainsi que la mise à disposition au public du contenu de la Plateforme ATCHOUM, chaque Membre autorise ATCHOUM, pour la France voire le monde entier et pour toute la durée de sa relation contractuelle avec ATCHOUM, à reproduire, représenter, adapter et traduire son Contenu Membre de la façon suivante :</p>
<p>il autorise ATCHOUM à reproduire tout ou partie de son Contenu Membre sur tout support d’enregistrement numérique, connu ou inconnu à ce jour, et notamment sur tout serveur, disque dur, carte mémoire, ou tout autre support équivalent, en tout format et par tout procédé connu et inconnu à ce jour, dans la mesure nécessaire à toute opération de stockage, sauvegarde, transmission ou téléchargement lié au fonctionnement de la Plateforme ATCHOUM et à la fourniture du Service ;</p>
<p>il autorise ATCHOUM à adapter et traduire son Contenu Membre, ainsi qu’à reproduire ces adaptations sur tout support numérique, actuel ou futur, stipulé au ci-dessus, dans le but de fournir les Fonctionnalités, notamment en différentes langues. Ce droit comprend notamment la faculté de réaliser, dans le respect de son droit moral, des modifications de la mise en forme de son Contenu Membre aux fins de respecter la charte graphique de la Plateforme ATCHOUM et/ou de le rendre techniquement compatible en vue de sa publication via la Plateforme ATCHOUM.</p>
<p><strong>12. Rôle d’ATCHOUM</strong></p>
<p>La Plateforme ATCHOUM constitue une mise en relation en ligne et pas son centre d’appels par laquelle les Membres peuvent créer et publier des Annonces de Covoiturage pour des Trajets en Covoiturage. Ces Annonces de Covoiturage peuvent notamment être consultées par les autres Membres pour prendre connaissance des modalités du Trajet et, le cas échéant, réserver directement une Place dans le véhicule concerné auprès du Membre ayant posté l’annonce sur la Plateforme ATCHOUM pour le trajet proposé ou un segment de celui-ci.</p>
<p>En utilisant la Plateforme ATCHOUM et en acceptant les présentes CGU, chaque Membre reconnait qu’ATCHOUM n’est partie à aucun accord conclu entre lui et les autres Membres en vue de partager les frais afférents à un Trajet.</p>
<p>ATCHOUM n’a aucun contrôle sur le comportement de ses Membres, de leurs éventuels tiers de confiance et des utilisateurs de la Plateforme ATCHOUM et ne possède pas, n’exploite pas, ne fournit pas, ne gère pas les véhicules objets des Offres de Trajet, ni ne propose le moindre Trajet pour son compte sur la Plateforme ATCHOUM.</p>
<p>Chaque Membre reconnait et accepte qu’ATCHOUM ne contrôle ni la validité, ni la véracité, ni la légalité des Annonces, des Places et Trajets proposés. En sa qualité d’intermédiaire en courvoiturage et covoiturage, ATCHOUM ne fournit aucun service de transport et n’agit pas en qualité de transporteur, le rôle d’ATCHOUM se limitant à faciliter l’accès à la Plateforme ATCHOUM et à mettre en œuvre des Fonctionnalités de mise en relation entre Conducteur offrant des Trajets et Passagers recherchant des Trajets dans les modalités décrites dans les présentes Conditions Générales d’Utilisations.</p>
<p>Dans le cadre d’un Trajet en Courvoiturage et Covoiturage, les Membres (Conducteurs ou Passagers) agissent sous leur seule et entière responsabilité, notamment conformément aux dispositions du code civil relatives au droit des obligations et à la responsabilité civile contractuelle (article 1101 et suivants du Code Civil).</p>
<p>En sa qualité d’intermédiaire, ATCHOUM ne saurait voir sa responsabilité engagée au titre du déroulement effectif d’un Trajet, et notamment du fait :</p>
<p>d’informations erronées communiquées par le Conducteur, dans son Offre de Trajet, ou par tout autre moyen, quant au Trajet et à ses modalités.</p>
<p>de l’annulation ou la modification d’un Trajet par un Membre.</p>
<p>du comportement de ses Membres pendant, avant, ou après le Trajet .</p>
<p><strong>13. Fonctionnement, disponibilité et Fonctionnalités de la Plateforme ATCHOUM</strong></p>
<p>ATCHOUM s’efforcera, dans la mesure du possible, de maintenir la Plateforme ATCHOUM accessible 7 jours sur 7 et 24 heures sur 24. Néanmoins, l’accès à la Plateforme ATCHOUM pourra être temporairement suspendu, sans préavis, en raison d’opérations techniques de maintenance, de migration, de mises à jour ou en raison de pannes ou de contraintes liées au fonctionnement des réseaux.</p>
<p>Par ailleurs ATCHOUM se réserve le droit de modifier ou d’interrompre, à sa seule discrétion, de manière temporaire ou permanente, tout ou partie de l’accès à la Plateforme ATCHOUM ou de ses fonctionnalités.</p>
<p><strong>14. Modification des CGU</strong></p>
<p>Les présentes Conditions Générales d’Utilisation et les documents intégrés par référence expriment l’intégralité de l’accord entre chaque Membre et ATCHOUM relatif à l’utilisation des Fonctionnalités de la plateforme ATCHOUM. Tout autre document, notamment toute mention sur la Plateforme ATCHOUM («&nbsp;Foire aux questions&nbsp;», «&nbsp;On parle de nous&nbsp;», «&nbsp;Actualités&nbsp;», etc.), n’a qu’une valeur informative.</p>
<p>ATCHOUM peut être amené à modifier les présentes Conditions Générales d’Utilisation afin de s’adapter à l’évolution de l’environnement technologique et commercial et afin de se conformer à la réglementation en vigueur. Toute modification des présentes Conditions Générales d’Utilisation sera publiée sur la Plateforme ATCHOUM avec une mention de la date de mise à jour et sera notifiée par ATCHOUM avant son entrée en vigueur à tous les Membres inscrits sur la plateforme ATCHOUM.</p>
<p><strong>15. Droit applicable – Litige</strong></p>
<p>Les présentes Conditions Générales d’Utilisation sont rédigées en français et soumises à la loi française.</p>
<p>Chaque Membre peut présenter, le cas échéant, ses réclamations relatives à la Plateforme ATCHOUM ou à ses Fonctionnalités, sur la Plateforme de résolution des litiges mise en ligne par la Commission Européenne accessible ici. La Commission Européenne se chargera de transmettre votre réclamation aux médiateurs nationaux compétents. Conformément aux règles applicables à la médiation, vous êtes tenus, avant toute demande de médiation, d’avoir fait préalablement part par écrit à ATCHOUM 36 Route de Toulon 83136 MEOUNES LES MONTRIEUX (France) de tout litige afin d’obtenir une solution amiable.</p>
<p>Conformément aux articles du code de la consommation L.611-1 et suivant, il est prévu que pour tout litige de nature contractuelle n’ayant pu être résolu dans le cadre d’une réclamation préalablement introduite auprès du service utilisateurs ATCHOUM, chaque Membre peut en sa qualité de consommateur, recourir gratuitement à la médiation en contactant l’Association Nationale des Médiateurs (ANM) soit par courrier en écrivant au 62, rue Tiquetonne 75002 PARIS soit par e-mail en remplissant le formulaire de saisine en ligne à l’adresse suivante: www.anm-conso.com</p>
<p>Le Site internet suivant www.economie.gouv.fr/mediation-conso comporte également toutes informations utiles en cas de litige.</p>
<p><strong>16. Mentions légales</strong></p>
<p>La Plateforme ATCHOUM est propulsée par la société ATCOUM SAS, société anonyme simplifiée au capital de 10&nbsp;000.00 euros immatriculée au Registre du Commerce et des Sociétés de Draguignan sous le numéro 831&nbsp;044&nbsp;763 00017 (numéro de TVA intracommunautaire : FR46 831044763) dont le siège social est situé 36 Route de Toulon 83136 MEOUNES LES MONTRIEUX (France) email : contact@atchoum.eu, tel 06 32 83 17 30, représentée par son Président Vincent DESMAS également Directeur de la publication du Site.</p>
<p>Le Site est hébergé sur les serveurs d’ATCHOUM SAS.</p>
<p>L’assurance de responsabilité civile professionnelle est souscrite auprès de :</p>
<p>Pour toute question, vous pouvez contacter ATCHOUM SAS en utilisant le formulaire de contact.</p>
<p><strong>17. Transparence de la Plateforme ATCHOUM</strong></p>
<p>Conformément à la réglementation applicable, vous trouverez ci-dessous les informations relatives au référencement et classement des Trajets sur notre Plateforme ATCHOUM. En cas de question à ce sujet, merci de contacter ATCHOUM en utilisant le formulaire accessible ici.</p>
<p>Référencement et classement des Trajets dans la page de résultats</p>
<p>Il n’existe aucun lien capitalistique ni rémunération de quelque nature que ce soit entre les Membres de la communauté et ATCHOUM susceptible d’influencer le référencement ou le classement des annonces sur la Plateforme ATCHOUM.</p>
<p>Tout Conducteur personne physique membre inscrit sur la Plateforme ATCHOUM est autorisé à publier un Trajet. Sous réserve que le Conducteur respecte les présentes Conditions Générales d’Utilisation, le Trajet sera publié et affiché parmi les résultats de recherche.</p>
<p>Il n’y a pas de classement dans l’offre des Trajets proposés, ceux-ci étant présentés de manière aléatoire par la plateforme en fonction de la demande des passagers et en tenant compte uniquement du lieu de départ et du lieu d’arrivée et des proximités pertinentes. C’est le passager qui effectue le choix du trajet correspondant à son souhait de déplacement.</p>

		</div>
	</div>
</div>
        </div>
     
      </Layout>
  );
};
export default Cgu;
