import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import IconClose from '../assets/images/iconClose.svg';
import Form from 'react-bootstrap/Form';
import Services from '../services/global.service';

function RaisonSignal({ open, setOpen, raison, setRaison ,user}) {
  
  const list = [
    "Comportement inapproprié",
    "Annulation de dernière minute",
    "Non-respect des règles de la plateforme",
    "Problème de sécurité",
    "Profil frauduleux",
    "Autre (veuillez préciser)"
  ];

  const [isOtherSelected, setIsOtherSelected] = useState(false); // For tracking "Autre"

  const handleRaisonChange = (e) => {
    const selectedValue = e.target.value;

    if (selectedValue === "Autre") {
      setIsOtherSelected(true);
      setRaison(""); // Reset raison if "Autre" is selected
    } else {
      setIsOtherSelected(false);
      setRaison(selectedValue); // Set raison from the selected radio button
    }
  };
  const HandleSignal=()=>{
    const data={
      raison:raison
    }
    Services.create(data,`signalUser/${user}`)
    .then((res)=>{
      console.log(res)
     setOpen(!open)

    })
    .catch((err)=>{
      console.log(err)
    })
  }

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500">Quelle est la raison de votre signalement ?</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>

      <Modal.Body>
        <Form>
          {list.map((item, index) => (
            <div className="mb-3" key={index}>
              <label className="d-flex exergue bleuM500 align-items-center">
                <input
                  type="radio"
                  name="raison"
                  value={item === "Autre (veuillez préciser)" ? "Autre" : item}
                  checked={raison === item || (isOtherSelected && item === "Autre (veuillez préciser)")}
                  onChange={handleRaisonChange}
                />
                <span className="custom-radio"></span> {item}
              </label>
            </div>
          ))}

          {/* Conditionally render input for "Autre" */}
          {isOtherSelected && (
            <div  className="floating-input-container mb-3">
            <input
              type="text"
              value={raison}
              onChange={(e) => setRaison(e.target.value)}
              className={`floating-input p-16`} 
              placeholder=" " // important for floating effect
            />
            <label className="floating-label">
            Raison du signalement
            </label>
            </div>
            // <div className="mt-3">
            //   <label className="d-flex align-items-center">
            //     <input
            //       type="text"
            //       placeholder="Raison du signalement"
            //       value={raison}
            //       onChange={(e) => setRaison(e.target.value)}
            //       className="form-control"
            //     />
            //   </label>
            // </div>
          )}
        </Form>
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button className="btn-primary-s p-16 w-100" onClick={HandleSignal}>
          Je confirme
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default RaisonSignal;
