import { useEffect, useState } from "react";
import moment from "moment";
import ReturnIcon from "../../assets/images/return.svg";
import Delete from "../../assets/images/Delete.svg";
import Check from "../../assets/images/check-orange.svg";
import EyeOpened from "../../assets/images/open-eye.svg";
import Header from "../../components/header";
import Services from "../../services/global.service";
import ShowDocModal from "../../components/showDoc";
import { toast } from "react-toastify";
import ModalType from "../../components/modalType";
import { useNavigate } from "react-router-dom";
import { validateFiles } from "../../utils/utils";

const Docs = () => {
  const navigate=useNavigate()
  const [open, setOpen] = useState(false);
  const [triggerFileInput, setTriggerFileInput] = useState(false);
  const [openType, setOpenType] = useState(false);
  const [type, setType] = useState("cin");
  const [url, setUrl] = useState("");
  const [docs, setDocs] = useState({
    justificatif: [],
    permis: [],
    carteGrise: [],
    assurance: [],
  });
  const isSubmitDisabled = Object.values(docs).some((files) => !files.length);
  // Handle image change for each document (allow multiple files)
  const handleImageChange = (e, docType) => {
    const files = Array.from(e.target.files);

    if (!validateFiles(files)) {
      toast.error("Seuls les fichiers JPG, PNG, PDF (moins de 2 Mo) sont autorisés !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
      });
      return;
    }
    if (files.length > 2) {
      toast.error("Vous ne pouvez sélectionner que deux fichiers merci !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        className: "custom-toast-error",
      });
      return;
    }
    if (files.length) {
      setDocs((prevDocs) => ({
        ...prevDocs,
        [docType]: [...prevDocs[docType], ...files], // Append new files to the existing ones
      }));
    }
  };

  const getDocs = () => {
    const idUser = localStorage.getItem("userData");
    Services.getOne(idUser, "users")
      .then((res) => {
        const documents = res?.data?.data?.documents || [];
        const updatedDocs = {
          justificatif: [],
          permis: [],
          carteGrise: [],
          assurance: [],
        };

        // Map documents to the corresponding doc types
        documents.forEach((doc) => {
          switch (doc.name) {
            case "piece_identite":
              updatedDocs.justificatif = [...updatedDocs.justificatif, doc];
              break;
            case "permis_de_conduire":
              updatedDocs.permis = [...updatedDocs.permis, doc];
              break;
            case "carte_grise":
              updatedDocs.carteGrise = [...updatedDocs.carteGrise, doc];
              break;
            case "attestation_assurance":
              updatedDocs.assurance = [...updatedDocs.assurance, doc];
              break;
            default:
              break;
          }
        });
        setDocs(updatedDocs);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getDocs();
  }, []);

  // Handle form submission (send multiple files)
  const handleSubmit = () => {
    const formData = new FormData();
    console.log('type',type)
    // Append multiple files for each document type
    if (!docs.justificatif[0]?.updated_at) {
      docs.justificatif.forEach((file) =>
        {
        formData.append("piece_identite[]", file)
        formData.append("type", type)
        }
      );
    }
    if (!docs.assurance[0]?.updated_at) {
      docs.assurance.forEach((file) =>
        formData.append("attestation_assurance[]", file)
      );
    }
    if (!docs.carteGrise[0]?.updated_at) {
      docs.carteGrise.forEach((file) => formData.append("carte_grise[]", file));
    }
    if (!docs.permis[0]?.updated_at) {
      docs.permis.forEach((file) =>
        formData.append("permis_de_conduire[]", file)
      );
    }

    Services.create(formData, "update_user")
      .then((res) => {
        navigate('/assurance-solidaire')
        // toast.success("Votre opération a réussi !", {
        //   position: "top-right",
        //   autoClose: 3000,
        //   hideProgressBar: true,
        //   closeOnClick: true,
        //   draggable: true,
        //   progress: undefined,
        //   onClose: () => navigate('/assurance-solidaire'),
        //   className: "custom-toast",
        // });
        getDocs();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          className: "custom-toast-error",
        });
      });
  };

  const showDoc = (url) => {
    setUrl(url);
    setOpen(!open);
  };

  const onDelete = (type) => {
    Services.create({ name: type }, "documents")
      .then((res) => {
        getDocs();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const openTypeModal = (docType) => {
    console.log(docType)
    if (docType === "justificatif") {
      //setType(docType); // set the document type to be used in the modal
      setOpenType(true); // open the modal
    } else {
      // For other document types, you can directly open the file input
      document.getElementById(`${docType}Input`).click();
    }
  };
  useEffect(() => {
    if (triggerFileInput) {
      document.getElementById("justificatifInput").click();
      setTriggerFileInput(false); // reset state after triggering
    }
  }, [triggerFileInput]);

  const handleModalConfirm = () => {
    console.log('hereeeeee')
    setOpenType(false); // close the modal
    setTriggerFileInput(true); // trigger the file input for justificatif
  };
  return (
    <>
      {open && url && <ShowDocModal open={open} setOpen={setOpen} url={url} />}
      {openType && (
        <ModalType open={openType} setOpen={setOpenType} type={type} setType={setType} 
        onConfirm={handleModalConfirm} />// Pass the confirm handler to the modal/>
      )}
      <div className="desktop">
        <Header connected={true} />
      </div>

      <div className="step6">
        <div className="container-fluid-lg">
          <h1 className="text-white p-16 d-flex align-items-center mb-100">
            <a href="javascript:history.go(-1)">
              <img src={ReturnIcon} className="mr-8" alt="return icon" />
            </a>
            Devenir conducteur solidaire
          </h1>
        </div>
        </div>
        <div className="col-lg-6 mx-auto">
         <div className="px-16 mt-16 container-fluid-lg">
          <h2 className="bleuM500">Ajoutez vos documents</h2>
          <p className="current-text bleuM500 text-start">
            Si vous ajoutez vos documents, vous aurez plus de chances de trouver
            des passagers.
          </p>

          {/* Justificatif d'identité */}
          <DocumentCard
            docTitle="Justificatif d’identité"
            docType="justificatif"
            deleteType="piece_identite"
            docs={docs.justificatif}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            className=""
            openTypeModal={openTypeModal} 

          />

          {/* Permis de conduire */}
          <DocumentCard
            docTitle="Permis de conduire"
            docType="permis"
            deleteType="permis_de_conduire"
            docs={docs.permis}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            className=""
            openTypeModal={openTypeModal} 

          />

          {/* Carte Grise */}
          <DocumentCard
            docTitle="Carte grise"
            docType="carteGrise"
            deleteType="carte_grise"
            docs={docs.carteGrise}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            className=""
            openTypeModal={openTypeModal} 
          />

          {/* Attestation d’assurance */}
          <DocumentCard
            docTitle="Attestation d’assurance"
            docType="assurance"
            deleteType="attestation_assurance"
            className="mb-80"
            docs={docs.assurance}
            handleImageChange={handleImageChange}
            onDelete={onDelete}
            showDoc={showDoc}
            openTypeModal={openTypeModal} 
          />
        </div>
        <div className="bottom-white-block">
          <button
            className="btn-primary-s p-16 w-sm-100"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            Suivant
          </button>
        </div>  
        </div>
       
   
    </>
  );
};

// Reusable DocumentCard component
const DocumentCard = ({ docTitle, docType, docs, handleImageChange, onDelete,deleteType, showDoc,openTypeModal, className }) => (
  <div className={`card-atch mt-8 ${className}`}>
    <div className="d-flex align-items-center justify-content-center mb-8">
      <div className="col">
        <p className="exergue text-center m-0 bleuM500">{docTitle}</p>
      </div>
      {docs.length > 0 && docs[0]?.updated_at && (
        <div className="text-end">
          <button className="cta" onClick={() => onDelete(deleteType)}>
            <img src={Delete} alt="delete icon" />
          </button>
        </div>
      )}
    </div>

    {docs.length ? (
      <div className="text-center">
        {docs[0]?.updated_at && (
          <span className="date-file">
            <img src={Check} className="mr-8" alt="check icon" />
            Ajouté le{" "}
            {new Date(docs[0]?.updated_at).toLocaleDateString("fr-FR")}
          </span>
        )}
        {docs.map((file, index) => (
          <div key={index}>
            <p className="legend text-center mb-0 mt-8">
              {file.name}
              {file?.updated_at && (
                <button className="cta" onClick={() => showDoc(file.full_uri)}>
                  <img src={EyeOpened} alt="show file icon" className="ml-8" />
                </button>
              )}
            </p>
          </div>
        ))}
      </div>
    ) : (
      <>
        <button
          className="btn-secondary-s p-14 w-100"
          onClick={() => openTypeModal(docType)}
        >
          Ajouter
        </button>
        <input
          type="file"
          id={`${docType}Input`}
          accept="image/*"
          multiple
          onChange={(e) => handleImageChange(e, docType)}
          style={{ display: "none" }}
        />
      </>
    )}

  </div>
);

export default Docs;
