import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import AddFriend from "../../components/addFriend";
import Avatar from "../../assets/images/Avatar.svg";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";

const Amis = () => {
  const [amis, setAmis] = useState([]); // Stores the list of friends
  const [open, setOpen] = useState(false); // Controls the "Add Friend" modal visibility
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const userId = parseInt(localStorage.getItem('userData')); // Current user ID
const navigate=useNavigate()
  // Function to fetch the list of friends
  const getList = () => {
    setIsLoading(true); // Start loading
    Services.getAll(`amis/liste-all-ami`)
      .then((res) => {
        console.log(res?.data)
        const data = Array.isArray(res?.data) ? res.data : [];
        setAmis(data);
      })
      .catch((err) => {
        console.error("Error fetching friends:", err);
      })
      .finally(() => {
        setIsLoading(false); // Stop loading
      });
  };

  // Fetch the list of friends when the component mounts
  useEffect(() => {
    getList();
  }, [open]);

  return (
    <div className="pt-16 px-16">
      {/* Add Friend Modal */}
      <AddFriend open={open} setOpen={setOpen} />

      {/* Loading State */}
      {isLoading ? (
        <Loader/> // Simple loader, can replace with a spinner or skeleton
      ) : amis && amis.length > 0 ? (
        <div className="col-lg-6 mx-auto">
          {/* List of friends */}
          {amis.map((el, index) => (
            <button
             onClick={()=>navigate(`/amis/${el?.ami?.id}`)}
              disabled={el?.etat === "en_attente" &&
                el?.demande==="envoye"}
              className="cta no-decoration w-100"
              key={index}
            >
              <div className="d-flex containerFriend p-8 mt-8 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <img
                    src={
                      el?.ami?.photo_profil?.length > 0
                        ? el?.ami?.photo_profil
                        : Avatar
                    }
                    alt="ami avatar"
                    className="driver-icon mr-8"
                  />
                  <p className="exergue bleuM500">
                    {el?.ami?.nom} {el?.ami?.prenom}
                  </p>
                </div>
                <div>
                  {/* Status handling */}
                  {el?.etat === "en_attente" &&  el?.demande==="recu" ? (
                    <div className="btn-secondary-s" 
                  >Voir la demande</div>
                  ) : el?.etat === "en_attente" &&
                  el?.demande==="envoye" ? (
                    <label className="sent">Demande envoyée</label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </button>
          ))}
          {/* Add Friend Button */}
          <div className="btn-block">
            <button
              className="btn-primary-s p-16 mt-24 w-100"
              onClick={() => setOpen(!open)}
            >
              Ajouter un ami
            </button>
          </div>
        </div>
      ) : (
        // Empty state when no friends are found
        <div className="col-lg-6 mx-auto card-atchoum p-24 text-center bg-white mt-100">
          <p className="subtitle bleuM500 text-center">
            Tenez vos amis informés de vos trajets avec Atchoum !
          </p>
          <p className="current-text bleuM500 mt-8 text-center">
            Proposez ou demandez leur directement des trajets et en tant que
            conducteur proposez les sans les faire payer si vous le souhaitez ! 
          </p>
          <button
            className="btn-primary-s p-16 mt-24 w-100"
            onClick={() => setOpen(!open)}
          >
            Ajouter un ami
          </button>
        </div>
      )}
    </div>
  );
};

export default Amis;
