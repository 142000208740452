import { useNavigate } from "react-router-dom"

const BlocResultEmpty=(props)=>{
    const navigate=useNavigate()
return(
   
    <>
  
        <div className="d-flex flex-column align-items-center">
            <p className={`${props?.className} text-center bleuM500 w-75`}>
           {props?.title}
            </p>
            <button onClick={()=>{navigate('/',{state:{searchData:props.data.data}})}} className="btn-secondary-s w-sm-100 p-16 mt-8">Je fais une demande de trajet solidaire</button>
        </div>
      

    </>
)
}
export default BlocResultEmpty