import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg';
import Form from 'react-bootstrap/Form';
import Services from '../services/global.service';
import { toast } from 'react-toastify';

function AddFriend({ open, setOpen }) {
  // State for form data
  const [formData, setFormData] = useState({
    tel: '',
    nomContact: '',
  });

  // State for errors
  const [errors, setErrors] = useState({});

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    if (!formData.tel) newErrors.tel = 'Le numéro de téléphone est requis.';
    else if (!/^\d+$/.test(formData.tel)) newErrors.tel = 'Entrez uniquement des chiffres.';
    
    if (!formData.nomContact) newErrors.nomContact = 'Le nom du contact est requis.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle Add Friend
  const handleAdd = () => {
    if (!validateForm()) return;

    Services.create(formData, `amis/add-ami`)
      .then((res) => {
        console.log(res);
        toast.success('Votre opération a réussi !', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
          onClose: ()=>setOpen(false),
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast'
  
        });
       setOpen(false);
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
        console.error(err);
      });
  };

  return (
    <Modal show={open} onHide={() => setOpen(false)} centered>
      <Modal.Header className="border-0 d-flex justify-content-between align-items-start">
        <h2 className="bleuM500">Ajouter un ami</h2>
        <button className="cta" onClick={() => setOpen(false)}>
          <img src={IconClose} alt="icon close" />
        </button>
      </Modal.Header>

      <Modal.Body>
        <Form>
          <div className="form-group floating-input-container">
            <input
              type="text"
              name="tel"
              className="floating-input radius-top"
              placeholder=""
              value={formData.tel}
              onChange={handleInputChange}
            />
            <label htmlFor="tel" className="floating-label">
              Numéro de téléphone
            </label>
            {errors.tel && <small className="text-danger">{errors.tel}</small>}
          </div>

          <div className="form-group floating-input-container">
            <input
              type="text"
              name="nomContact"
              className="floating-input radius-bottom"
              placeholder=""
              value={formData.nomContact}
              onChange={handleInputChange}
            />
            <label htmlFor="nomContact" className="floating-label">
              Nom du contact
            </label>
            {errors.nomContact && <small className="text-danger">{errors.nomContact}</small>}
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer className="border-0">
        <button className="btn-primary-s p-14 w-100" onClick={handleAdd}>
          Ajouter
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddFriend;
