import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import { useNavigate } from "react-router-dom";

const Groups = () => {
    const [groups, setGroups] = useState([]);
    const navigate = useNavigate();
    const file = process.env.REACT_APP_UPLOAD_URL
    // Function to fetch the list of groups
    const getList = () => {
        Services.getAll('mygroup')
            .then((res) => {
                console.log("Response:", res);  // Log entire response
                const data = Array.isArray(res?.data) ? res?.data : [];
                setGroups(data);
                console.log("Groups set:", data);  // Log what is set in groups
            })
            .catch((err) => {
                console.error("Error fetching groups:", err);
            });
    };

    useEffect(() => {
        getList(groups);
    }, []);

    return (
        <div className="pt-16 px-16 col-lg-6 mx-auto">
            {groups.length === 0 ? (
               <div className="bg-white card-atchoum p-24 text-center mt-100">
             <p className="subtitle bleuM500 text-center">
            Entreprise, Club sportif, Association…
            </p>
            <p className="current-text bleuM500 mt-8 text-center">
            Créez et rejoignez des Groupes de covoiturage, partagez vos demandes et propositions de trajets, 
            et proposez-les sans les faire payer si vous le souhaitez ! 
            </p>
               <button className="btn-primary-s p-16 w-100 mt-24 position-bottom" onClick={() => navigate('/group/add')}>
                Créer un groupe
            </button>
             </div>
            ) : (
                <>
               { groups.map((el, i) => (
                    <a href={`group/${el?.id}`} className="cta no-decoration" key={i}>
                        <div className="d-flex  containerFriend p-8 mt-8 align-items-center justify-content-between">
                            <div className="d-flex align-items-center group-icon">
                                {el.members.map((usr, i) => (
                                    <img src={usr?.group_user?.photo_profil} alt="driver" className="driver-icon-group mr-8" key={i} />
                                ))}
                                <p className="exergue bleuM500">{el?.groupe_name}</p>
                            </div>
                        </div>
                    </a>
                ))}
                
            <button className="btn-primary-s p-16 w-100 mt-24 position-bottom" onClick={() => navigate('/group/add')}>
                Créer un groupe
            </button>
                </>
            )}
            
        </div>
    );
};

export default Groups;
