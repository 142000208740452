
import Modal from "react-bootstrap/Modal";
import Signal from "../../assets/images/signal.svg";
import Trash from "../../assets/images/trashbtn.svg";
import Services from "../../services/global.service";
import RaisonSignal from "../../components/modalRaison";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function PopUpConfirmation({show,setShow,idDisc,idUser}) {
  console.log({idUser})
  const[open,setOpen]=useState(false)
  const[raison,setRaison]=useState('')
  const navigate=useNavigate()
const deletedisc=()=>{
  Services?.deleteByid(idDisc,'messages/deleteDiscussion')
  .then((res)=>{
    console.log(res)
    navigate('/mes-messages')
  })
  .catch((err)=>{
    console.log(err)
  })
}


  return (
    <>
      {open && <RaisonSignal open={open} setOpen={setOpen} raison={raison} setRaison={setRaison} user={idUser} />}
      <Modal
        show={show}
       onHide={()=>setShow(false)}
    className="msg-modal"
        keyboard={false}
      >
        <Modal.Body>
          <div className=" exergue ">
            {" "}
            <bouton  className="d-flex align-items-center justify-content-between" onClick={deletedisc}>
              Supprimer le conversation <img src={Trash} alt="trash icon" />
            </bouton>{" "}
          </div>

          <hr />

          <div className="">
            {" "}
            <bouton  className="rouge500 exergue d-flex align-items-center justify-content-between"
            onClick={()=>
            {
              setOpen(!open)
              setShow(!show)
            }
            }>
              Signaler <img src={Signal} alt="signal icon" />
              </bouton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PopUpConfirmation;
