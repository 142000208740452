import React, { useState } from "react";
import Avatar from "../../assets/images/Avatar.svg";
import readMore from "../../assets/images/more-fill.svg";
// import PopUpConfirmation from "./popUpConfirmation";
import DeleteMessage from "./deletemsg";


function DetailMessage({setMessages,id, text, isMine ,friendAvatar}) {
// console.log({id})
  const [show, setShow] = useState(false);

  return (
    <>
      <div className="row mt-8">
        <div className="col-2 mt-20">
          {!isMine && (<img src={friendAvatar || Avatar} alt="avatar"   className="driver-icon mr-8" />)}  
        </div>

        <div className="col-10 section-msg mt-20 d-flex space-between">
          <div
            className={`row justify-content-between  bull-msg color-bull ${
              isMine ? "red" : "bull-shapeButtom"
            }`}
          >
            <div className="text-start font-Livvic font-16 p-10 message-text">{text}</div>
          </div>
          {isMine && (
            <><img src={readMore} alt="actions msg" 
            onClick={()=>setShow(!show)} className="readMore-style ml-20" />
       <DeleteMessage setMessages={setMessages} show={show} setShow={setShow} idMsg={id} />  
          
          </>) }
        </div>
      </div>



    </>
  );
}

export default DetailMessage;
