import { useNavigate, useParams } from "react-router-dom";
import Friend from "../messagerie/friend";
import Search from "../messagerie/search";

const Membres = ({ filteredAmis, amis,reloadData, setFilteredAmis, withBtn }) => {
  const params = useParams();
  const navigate = useNavigate();
  const onSearch = (value) => {
    const filtered = amis.filter(
      (el) =>
        (el?.friend?.nom &&
          el.friend?.nom.toLowerCase().includes(value.toLowerCase())) ||
        (el.friend?.prenom &&
          el.friend?.prenom.toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredAmis(filtered); // Updates filtered list
  };

  // Reset the filtered amis to the full list
  const resetFiltered = () => {
    setFilteredAmis(amis);
  };
  return (
    <div className="container-fluid-lg col-lg-6 mx-auto px-16">
      <div className="mt-16 mx-auto">
        <p className=" mt-8 subtitle bleuM500 text-start">
          Inviter les personnes qui feront parti du groupe
        </p>
        <div>
          <Search
            placeholderText="Rechercher un ami(e)"
            onSearch={onSearch}
            resetFiltered={resetFiltered}
          />
          <p className="font-Livvic title-dis text-start mt-24">Suggestions</p>

          <div className="mt-16">
            {filteredAmis.map((el) => (
              <Friend
                key={el?.friend?.id}
                name={`${el?.friend?.nom} ${el?.friend?.prenom}`}
                avatar={
                  el?.friend?.photo_profil &&
                  el?.friend?.photo_profil.length > 0
                    ? el?.friend?.photo_profil
                    : ""
                }
                id={el?.friend?.id}
                group={true}
                gid={params.id}
                is_in_group={el?.is_in_group}
                 reloadData={reloadData}
              />
            ))}
          </div>
        </div>
      </div>
      {withBtn && (
        <div className="bottom-white-block mt-4">
          <div className="text-center">
            <button
              onClick={() => navigate(`/group/${params.id}`)}
              className="btn-primary-s p-16 w-sm-100 mt-2"
              // disabled={isSubmitting || values?.groupName===""}
            >
              Créer le groupe
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Membres;
