import { useLocation, useNavigate } from "react-router-dom";
import ReturnIcon from "../../assets/images/return.svg";
import NOImage from "../../assets/images/no-image.svg";
import Tears from "../../assets/images/tearsRight.svg";
import Pen from "../../assets/images/pen.svg";
import Completed from "../../assets/images/completed.svg";
import "../../assets/styles/bulletin.scss";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";

const Photo = () => {
  const navigate = useNavigate();
  const location=useLocation()
  const [user,setUser]=useState(location.state.user)
  console.log('user',user)
  const [image, setImage] = useState(user.photo_profil || null);
  const [file, setFile] = useState(null); // State to keep track of the uploaded file

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Set the preview image
      };
      reader.readAsDataURL(selectedFile); // Read the file as a data URL
      setFile(selectedFile); // Store the selected file
    } else {
      setImage(null); // Reset image preview
      setFile(null); // Reset file state
    }
  };

  const handleSubmit = () => {
    if (file) {
      const formData = new FormData();
      formData.append('photo_profil', file); // Append the file to FormData

      // Call the service to upload the file
      Services.create(formData, 'update_user')
        .then((res) => {
          console.log(res);
          // Navigate to the next page or handle success
          navigate('/vehicule-solidaire');
        })
        .catch((err) => {
          console.log(err);
        });
    }
    else{
      navigate('/vehicule-solidaire');
    }
  };
  const getMyProfil=()=>{
    const idUser=localStorage.getItem('userData')
    console.log('userData',idUser)
    Services.getOne(idUser,'users')
    .then((res)=>{
      console.log(res)
      setUser(res?.data?.data)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    if(user?.photo_profil.length>0){
      //in backend delete {} empty 
      setImage(user?.photo_profil)
    }
    else{
      setImage(null)
    }
   
  },[user])
  useEffect(()=>{getMyProfil()},[])
  return (
    <>
      <div className="bg-grey">
        <div className="step3 ">
          <div className="container-fluid-lg p-16">
            <h1 className="text-white d-flex">
              <button onClick={() => navigate(-1)} className="cta">
                <img src={ReturnIcon} className="mr-8" alt="return icon" />
              </button>
              Devenir conducteur solidaire
            </h1>
          </div>
        </div>
        <div className="col-lg-6 mx-auto p-16">
          <h2 className="m-0 bleuM500 container-fluid-lg">
            Ajoutez une photo de vous
          </h2>
          <p className="current-text mt-8">
            Une photo permet aux passagers de mieux vous reconnaitre.
          </p>
          <div className="card-atch mt-8">
            <div className="image-upload pt-16">
              <div className="position-relative icon-container-profil">
                <div className="image-preview-solidaire">
                  {image ? (
                    <img src={image} alt="Aperçu" className="image-preview__image" />
                  ) : (
                    <img src={NOImage} alt="NOImage" className="w-auto" />
                  )}
                </div>
                <img src={Tears} alt="Icon" className="floating-icon-solidaire" />
              </div>
              <button
                className="btn-secondary-s p-14"
                onClick={() => document.getElementById("fileInput").click()}
              >
                Ajouter une photo de profil
              </button>
              <input
                type="file"
                id="fileInput"
                accept="image/*"
                onChange={handleImageChange}
                style={{ display: "none" }} // Hide the file input
              />
            </div>
            <div className="bottom-white-block">
              <button
                type="button"
                className="btn-primary-s p-16 w-sm-100"
                onClick={handleSubmit} // Call handleSubmit on button click
                disabled={!file && !image} // Disable button if no file is selected
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Photo;
