import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// Import assets
import "../../assets/styles/login.scss";
import EyeOpened from "../../assets/images/open-eye.svg";
import EyeClosed from "../../assets/images/eyeClosed.svg";
import ReturnIcon from "../../assets/images/return.svg";
import Tears from "../../assets/images/tears.svg"
import Alert from "../../assets/images/danger.svg";
import Header from '../../components/header';
import Services from '../../services/global.service';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const navigate=useNavigate()
  const initialValues = {
    email: '',
    password: '',
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Format de l\'email invalide')
      .required('L\'email est requis'),
    password: Yup.string()
      .min(8, 'Le mot de passe doit comporter au moins 8 caractères')
      .required('Le mot de passe est requis'),
  });
  

  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  // Handle form submission
  const handleSubmit = (values, { setSubmitting }) => {
    console.log('Form data:', values);
    // Simulate async operation
    Services.create(values,'login')
    .then((res)=>{
      console.log(res)
      localStorage.setItem("token",res.data.token)
      localStorage.setItem("userData",res.data.user.id)
      window.location.href ='/'
    })
    .catch((err)=>{
      toast.error(err?.response?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
       // theme: 'light', // Choose theme (light/dark)
        className: 'custom-toast-error'

      });
      console.log(err)
    }
  )
    setTimeout(() => {
      setSubmitting(false);
    }, 1000);
  };

  return (
    <>
    <div className="desktop">
       <Header/> 
    </div>
  
    <div className="bg-atchoum">
    <div className="container-fluid-lg">
      <h1 className="text-white p-16 d-flex">
      <button onClick={() => navigate(-1)} className='cta'>
        <img src={ReturnIcon} className="mr-8" alt="return icon" />
        </button>
        Connexion
      </h1>
    </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched ,isValid,dirty}) => (
          <Form className="mt-28 ">
            <div className="p-16 position-relative  icon-container  col-lg-4 mx-auto">
            <img src={Tears} alt="Icon" className="floating-icon" /> 
              {/* Email Field */}
              <div className="floating-input-container">
                <Field
                  type="email"
                  name="email"
                  className={`floating-input radius-top ${touched.email && errors.email ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder=" " // important for floating effect
                />
                <label htmlFor="email" className="floating-label">
                  Adresse e-mail
                </label>
              
              </div>

              {/* Password Field */}
              <div className="floating-input-container position-relative">
                <Field
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  className={`floating-input radius-bottom ${touched.password && errors.password ? 'is-invalid' : ''}`}
                  id="password"
                  placeholder=" " // important for floating effect
                />
                <label htmlFor="password" className="floating-label">
                  Mot de passe
                </label>
                <div
                  className="password-toggle-icon position-absolute"
                  style={{
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    cursor: 'pointer',
                  }}
                  onClick={handleToggle}
                >
                  {showPassword ? (
                    <img src={EyeOpened} alt="Hide Password" />
                  ) : (
                    <img src={EyeClosed} alt="Show Password" />
                  )}
                </div>
               
              </div>
              <div className="text-end mt-8">
            <a href="/reset" className="cta">Mot de passe oublié ?</a>  
            </div>
            <ErrorMessage name="email" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
                    <ErrorMessage name="password" >
                   {msg => (
                    <div className="error-message">
                      <img src={Alert} alt="Alert" />
                      {msg}
                    </div>
                  )}
                    </ErrorMessage>
            </div>
                    
            {/* <ErrorMessage name="password" component="div" className="invalid-feedback" />
            <ErrorMessage name="email" component="div" className="invalid-feedback" /> */}
            
            <div className="bottom-white-block">
              <p className="current-text text-center-lg">
                Pas encore de compte ? <a href="/register" className="cta">Je m'inscris</a>
              </p>
              <button type="submit" disabled={!(isValid && dirty)} className="btn-primary-s mt-24 p-16 w-sm-100">Je me connecte</button>
            </div>
          </Form>
        )}
      </Formik>
     
      
    </div>
    </>
  );
};

export default Login;
