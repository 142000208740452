import { Card, CardBody, CardImg } from "react-bootstrap";
import Quote from '../assets/images/close-quote.svg';

const ItemTestimonials = ({ data }) => {
  return (
    <Card className="card-atchoum p-16 h-250" style={{ minHeight: '150px' }}>
      <CardBody className="p-0" style={{ minHeight: '100%' }}>
        <div className="row">
          <div className="col-auto mx-0 pr-0">
            <img src={Quote} alt="close Quote icon" />
          </div>
          <div className="col pl-0">
            <p className="textccm">{data?.avis}</p>
            <p className="textccm">
              {data?.author} <br /> {data?.function}
            </p>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ItemTestimonials;
