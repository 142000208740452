import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ItemCarousel from "./itemCarousel";
import ItemTestimonials from "./itemTestimonials";
const Testimonials = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1, // Show a little more than one item
      partialVisibilityGutter: 20, // Adjust the gutter for better visibility
    },
  };
  const data = [
    {
      id: 1,
      author: "Chantal et Eric",
      function: "Conducteurs pour Atchoum depuis 2021",
      avis: "Rendre service à toutes ses personnes c’est très gratifiant et voir leurs sourires sur leurs visages cela fait plaisir. On les sort de leur isolement pendant 1 ou 2 heures, on parle de tout et de rien et croyez-moi ça leur fait vraiment plaisir car ils sont très souvent seuls nous sommes leurs seules visites.",
    },
    {
      id: 2,
      author: "Brigitte",
      function: "Passager sur Atchoum depuis 2021",
      avis: "Il est difficile lorsque l’on n’a pas de véhicule de pouvoir aller à des rendez-vous médicaux, donc un grand merci à Atchoum de me trouver des conducteurs, et un grand merci à Chantal pour son dévouement.",
    },
    {
      id: 3,
      author: "Jean-Marie",
      function: "Passager sur Atchoum depuis 2021",
      avis: "Comme toujours, Hervé a été très sympathique, il est venu me chercher chez moi, et m'a permis de faire mes petites courses, et avec toute sa générosité.",
    },
    // {
    //   id: 4,
    //   author: "Chantal et Eric",
    //   function: "Conducteurs pour Atchoum depuis 2021",
    //   avis: "Rendre service à toutes ses personnes c’est très gratifiant et voir leurs sourires sur leurs visages cela fait plaisir. On les sort de leur isolement pendant 1 ou 2 heures, on parle de tout et de rien et croyez-moi ça leur fait vraiment plaisir car ils sont très souvent seuls nous sommes leurs seules visites.",
    // },
  ];
  return (
    <div className="container mt-42">
      <h2>Les témoignages</h2>
      <Carousel partialVisible={true}   responsive={responsive} next={1} className="mt-24">
        {data.map((item, index) => {
          return <ItemTestimonials data={item} key={index} />;
        })}
      </Carousel>

    </div>
  );
};
export default Testimonials;
