import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg'
import { useNavigate } from 'react-router-dom';

function ModalConnexion({open,setOpen}) {
  const navigate=useNavigate()
  return (
    <Modal show={open} onHide={()=>{setOpen(false)}}>
      <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
           
            </Modal.Title>
            <button className="cta" onClick={()=>setOpen(!open)}><img src={IconClose} alt="icon close"/></button>
         
        </Modal.Header>
   
    <Modal.Body className='text-center'>
    <p className="current-text bleuM800">Connectez-vous ou créez un compte pour accéder 
    à votre profil, gérer vos informations et votre compte.</p>
   
   <div className="mt-24 w-100">
   <button className="btn-primary-s w-100  mt-24 no-decoration" onClick={()=>navigate('/login')} >
        Se connecter
      </button>
    </div>   
      <p className="legend mt-8 text-center">
      Vous n’avez pas un compte ? 
     
      </p>
     <p>
      <a className='cta' href='/register'>
      Inscrivez-vous ici
      </a>
      </p> 
    </Modal.Body>
  </Modal>
  );
}

export default ModalConnexion;