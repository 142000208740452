import React, { useEffect, useState } from "react";
import newMessage from "../../assets/images/NewMessage.svg";
import "../../assets/styles/messagerie.scss";
import Search from "./search";
import Message from "./message";
import Services from "../../services/global.service";
import TopBar from "../../components/topBar";
import Header from "../../components/header";
import AvatarGrp from "../../assets/images/AvatarGrp.svg";
import SpinLoader from "../../components/SpinLoader";

const ListMessages=()=> {
  const [listMsg, setListMsg] = useState([]); // Original message list
  const [filteredMessages, setFilteredMessages] = useState([]); // Filtered message list
  const [loading, setLoading] = useState(true); // Loading state
  const getListOld = () => {
    Services.getAll("messages/showDiscussion")
      .then((res) => {
        const messages = Array.isArray(res?.data) ? res?.data : [];
        setListMsg(messages);
        setFilteredMessages(messages); // Initialize both states with fetched data
        console.log("Fetched messages:", messages);
      })
      .catch((err) => {
        console.log("Error fetching messages:", err);
      });
  };
  const getList = () => {
    setLoading(true); // Start loading
    Services.getAll("messages/showDiscussion")
      .then((res) => {
        const messages = Array.isArray(res?.data) ? res?.data : [];
  
        // Trier les messages selon la date du dernier message
        const sortedMessages = messages.sort((a, b) => {
          const dateA = new Date(
            a?.discussion_message[0]?.created_at || 0
          );
          const dateB = new Date(
            b?.discussion_message[0]?.created_at || 0
          );
          return dateB - dateA; // Tri décroissant (plus récent en premier)
        });
  
        setListMsg(sortedMessages);
        setFilteredMessages(sortedMessages); // Initialisez les deux états avec les messages triés
        console.log("Fetched and sorted messages:", sortedMessages);
        setLoading(false); // Stop loading
      })
      .catch((err) => {
        console.log("Error fetching messages:", err);
        setLoading(false); // Stop loading
      });
  };
  
  const onSearch = (value) => {
    console.log("Search value:", value);

    // Safeguard against undefined/null `content` and normalize search
    const filtered =
      listMsg.filter((el) =>
        el?.discussion_message[0]?.message
          ?.toLowerCase()
          ?.includes(value?.toLowerCase())
      ) || [];

    setFilteredMessages(filtered);
    console.log("Filtered messages:", filtered);
  };

  const resetFiltered = () => {
    setFilteredMessages(listMsg); // Reset to original list
  };

  useEffect(() => {
    getList(); // Fetch messages when the component mounts
  }, []);

  return (
    <>
      <div className="desktop">
        <Header connected={true} />
      </div>
      <div className="container-fluid-lg  p-16 bg-grey">
        <div className="col-lg-6 mx-auto">
          <div className="d-flex justify-content-between">
          <h1>Mes messages</h1>
          <a className="cta" href="listAmis">
            <img src={newMessage} alt="messagerie" />
          </a>
        </div>
        <Search
          onSearch={onSearch}
          resetFiltered={resetFiltered}
          placeholderText="Rechercher un message"
        />
        {loading ? (
            // Loader
            <div className="text-center my-5">
              <SpinLoader />
            </div>
          ) : filteredMessages?.length > 0 ? (
          filteredMessages.map((msg, index) => {
            const userId = localStorage.getItem("userData");

            const title =
              msg?.group_discussion?.groupe_name === "prive"
                ? msg?.members
                    ?.filter((member) => member.user_id != userId)
                    ?.map(
                      (member) =>
                        `${member.group_user.nom} ${member.group_user.prenom}`
                    )
                    .join(", ")
                : msg?.group_discussion?.groupe_name;

            const image =
              msg?.group_discussion?.groupe_name === "prive"
                ? msg?.members
                    ?.find((member) => member?.user_id != userId)
                    ?.group_user?.photo_profil
                : AvatarGrp;

            return (
              <Message
                key={index}
                image={image}
                title={title}
                text={msg?.discussion_message[0]?.deleted_msg_sender && msg?.discussion_message[0]?.sender_id==localStorage.getItem('userData') ?'Message supprimé.':msg?.discussion_message[0]?.message}
                date={msg?.discussion_message[0]?.created_at}
                number={msg?.messageNonLu}
                id={msg?.id}
              />
            );
          })
        ) : (
          <p>Aucun message trouvé.</p>
        )}
        <div className="h-xs-100"></div>
      </div>  
        </div>
      
      <TopBar />
    </>
  );
}

export default ListMessages;

