const BlocResultEmptySolidaire=(props)=>{
return(
    <>
  
        <div className="d-flex flex-column align-items-center">
            <div className="h-xs-100"></div>
            {props.title &&<p className={`subtitle text-center bleuM500 w-75`}>
            Aucun résultat pour votre trajet recherché ...
            </p>
}
            <p className="current-text text-center bleuM500">
            Pour toute demande d’information.
            </p>
            <p className="exergue bleuM500 text-center">
            Contactez-nous via la hotline : <br/>
            0 806 110 444 
            </p>
            <p className="legend bleu400">(Service gratuit + prix appel local)</p>
        </div>
      

    </>
)
}
export default BlocResultEmptySolidaire