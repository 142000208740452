import Autocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Services from "../services/global.service";
import CalendarAtchoum from "./calendar";

const Covoiturage = () => {
  const [depart, setDepart] = useState("");
  const [destination, setDestination] = useState("");
  const [departLatLng, setDepartLatLng] = useState(null);
  const [destinationLatLng, setDestinationLatLng] = useState(null);
  const [departDateTime, setDepartDateTime] = useState('');
  const [seats, setSeats] = useState(null);
  const [modalCalendar, setModalCalendar] = useState(false);
  const [confirmationDetails, setConfirmationDetails] = useState({ depart: {}, arrive: {} });
  const [forminput, setForminput] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const [commune, setCommune] = useState({ dept: "", dest: "" });

  const navigate = useNavigate();

  useEffect(() => {
    if (window.google) setIsGMapsLoaded(true)
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    }
  }, []);
const createTarget=()=>{
  localStorage.removeItem('detailsTrajet')
  localStorage.removeItem('weekDays')
  navigate("/details-covoiturage")
}
  const handleSelect = async (address, type) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      if (type === "destination") {
        if (
          address === depart || 
          (departLatLng && latLng.lat === departLatLng.lat && latLng.lng === departLatLng.lng)
        ) {
          alert("La destination doit être différente du départ.");
          return;
        }
        setDestination(address);
        setDestinationLatLng(latLng);
      } else {
        if (
          address === destination || 
          (destinationLatLng && latLng.lat === destinationLatLng.lat && latLng.lng === destinationLatLng.lng)
        ) {
          alert("Le départ doit être différent de la destination.");
          return;
        }
        setDepart(address);
        setDepartLatLng(latLng);
      }
      const addressComponents = results[0].address_components;
      // Find the component with 'locality' type for city/commune
      const cityComponent = addressComponents.find(component =>
        component.types.includes('locality')
      );

      // Set the commune (city)
      console.log(cityComponent)
      if (cityComponent) {
        const cityName = cityComponent.long_name;
        setCommune((prevCommune) => {
          if (type === "destination") {
            return { ...prevCommune, dest: cityName }; // Set destination commune
          } else if (type === "depart") {
            return { ...prevCommune, dept: cityName }; // Set departure commune
          }
          return prevCommune;
        });
      } else {
        console.error('Commune not found in the selected address');
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleModalCalendar = (value) => {
    setForminput(value);
    setModalCalendar(!modalCalendar);
  };

  const formatDate = (dateString, setState) => {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const date = new Date(year, month - 1, day);

    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    const dayName = daysOfWeek[date.getDay()];
    const monthName = monthNames[month - 1];
    const [hours, minutes] = timePart.split(':');

    const formattedDate = `${dayName} ${day.toString().padStart(2, '0')} ${monthName} à ${hours}h${minutes}`;
    setState(formattedDate);
  };

  useEffect(() => {
    if (Object.keys(confirmationDetails?.depart).length !== 0) {
      confirmationDetails.depart.isFlexible
        ? setDepartDateTime('Je suis flexible')
        : formatDate(confirmationDetails?.depart?.date, setDepartDateTime);
    }
    //console.log('sets', !destination, !depart, !confirmationDetails?.depart?.date, !seats)
    setIsDisabled(!destination || !depart || Object.keys(confirmationDetails?.depart)?.length === 0 || !seats)

  }, [confirmationDetails, seats, destination, depart]);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      adresseDepart: depart,
      adrDepartLongitude: departLatLng?.lng,
      adrDepartAltitude: departLatLng?.lat,
      adresseDestination: destination,
      adresseDesLongitude: destinationLatLng?.lng,
      adresseDesAltitude: destinationLatLng?.lat,
      dateDepart: confirmationDetails?.depart?.date,
      flexible: confirmationDetails?.depart?.isFlexible,
      isFlexibleDept: confirmationDetails?.depart?.isFlexible,
      selectedDayDept: confirmationDetails.depart?.selectedDays,
      selectedPeriodsDept: confirmationDetails?.depart?.selectedPeriods,
      time: `${confirmationDetails?.depart?.time?.hour || '00'}:${confirmationDetails?.depart?.time?.minute || '00'}:00`,
      nbrPlace: parseInt(seats),
      communes: commune
    };
    console.log(data)
    Services.create(data, "trajet/v2/recherche")
      .then((res) => {
        console.log("Response", res.data);
        navigate('/search', { state: { searchData: res.data, inputs_data: data } });
      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mb-3 p-16">
        {modalCalendar && (
          <CalendarAtchoum
            open={modalCalendar}
            setOpen={setModalCalendar}
            home={true}
            type={forminput}
            setConfirmationDetails={setConfirmationDetails}
            confirmationDetails={confirmationDetails}
          />
        )}

        {/* Autocomplete for Depart */}
        <div className="form-floating">
          {isGMapsLoaded && <Autocomplete
            value={depart}
            searchOptions={{ componentRestrictions: { country: ['fr'] },
            types: ["address"],
          }}
            onChange={setDepart}
            onSelect={(addr) => handleSelect(addr, "depart")}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="floating-input-container">
                <input
                  {...getInputProps({ placeholder: "", className: "floating-input input-location  input-home radius-top" })}
                  id="floatingAdr"
                />
                <label htmlFor="floatingAdr" className="floating-label home-label">Adresse de départ</label>
                <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                  {loading ? <div>Loading...</div> : null}
                  {suggestions.map((suggestion, index) => {
                    const style = { backgroundColor: suggestion.active ? "#41B6E6" : "#fff" };
                    // console.log('index', suggestion)
                    return (
                      <div

                        className="mapItem"
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Autocomplete>
          }
        </div>

        {/* Autocomplete for Destination */}
        <div className="form-floating">
          {isGMapsLoaded && <Autocomplete
            value={destination}
            searchOptions={{ componentRestrictions: { country: ['fr'] },
            types: ["address"],
          }}
            onChange={setDestination}
            onSelect={(addr) => handleSelect(addr, "destination")}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="floating-input-container mb-3">
                <input
                  {...getInputProps({ placeholder: "", className: "floating-input input-home radius-bottom" })}
                  id="floatingDestination"
                />
                <label htmlFor="floatingDestination" className="floating-label home-label">Adresse de destination</label>
                <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                  {loading ? <div>Loading...</div> : null}
                  {suggestions.map((suggestion, index) => {
                    const style = { backgroundColor: suggestion.active ? "#41B6E6" : "#fff" };
                    return (
                      <div
                        key={index}
                        className="mapItem"
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Autocomplete>
          }
        </div>

        {/* Departure and Arrival DateTime */}
        <div className="form-floating">
          <input
            type="text"
            value={departDateTime}
            onClick={() => handleModalCalendar('depart')}
            className="date-home input-home  floating-input"
            placeholder="Date et heure de départ"
            readOnly
          />
        </div>



        {/* Number of Seats */}
        <div className="floating-input-container mt-8">
          <input
            type="number"
            value={seats}
            onChange={(e) => setSeats(e.target.value)}
            className="floating-input input-home"
            placeholder=""
            min={1}
          />
          <label htmlFor="seats" className="floating-label home-label">Nombre de places souhaitées</label>
        </div>



        <button type="submit" className="btn-primary-s mt-16 p-16 w-100" disabled={isDisabled}>Rechercher un covoiturage</button>
        <div className="mt-16">
          <button  className="btn-secondary-s  p-16 w-100 no-decoration" onClick={createTarget}>Proposer un covoiturage</button>
        </div>

      </form>
    </>
  );
};

export default Covoiturage;
