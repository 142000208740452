import "../assets/styles/headerblue.scss";
import ReturnIcon from "../assets/images/return.svg";
import { useNavigate } from "react-router-dom";
import SearchDetails from "./searchDetails";

const Headerblue = ({data}) => {
    const navigate=useNavigate()
    console.log('data blue', data)
    return (
        <>
            <header className="blue-header">
                <div className="col col-lg-auto">

                    <h1 className=" d-flex">
                        <button onClick={() => navigate(-1)} className='cta'><img src={ReturnIcon} className="mr-8" alt="return icon" /></button>
                    </h1>
                    <SearchDetails data={data}/>

                </div>
            </header>
        </>
    );
};
export default Headerblue;