import { useNavigate } from 'react-router-dom'
import Avatar from '../assets/images/conducteur.svg'
const BlocConducteur=({data,searchData})=>{
  console.log(searchData)
  const navigate=useNavigate()
return(
    <>
    
      {/* Driver Information Block */}
      
      <div className="card-atchoum cursor-pointer bg-white p-16 text-start col-md-4 mt-8">
      <div onClick={()=>{navigate(`/conducteur/${data.id}`, { state: { searchData } })}} className="no-decoration">
      <div className="driver-details">
        <div className="driver-info">
          {data?.photo_profil_url ?
          
          <img src={data?.photo_profil_url} alt="driver" className="driver-icon mr-8" /> 
          :
            
          <img src={Avatar} alt="driver" className="driver-icon mr-8" />}
          <div className="driver-name-status">
            <p className='exergue bleuM500 mb-0'>{data?.prenom}</p>
           {data?.description !=="" && data?.documents?.length>3 && data?.vehicule &&   <div className="badge-confirme ">Conducteur confirmé</div>}
          </div>
        </div>
      </div> 
      </div>
      </div> 
   
    </>
)
}
export default BlocConducteur;