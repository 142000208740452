import "../../assets/styles/home.scss";
import { useEffect, useState } from "react";
import Services from "../../services/global.service";
import Header from "../../components/header";
import Headerblue from "../../components/headerblue";
import { useLocation } from "react-router-dom";
import BlocConducteur from "../../components/blocConducteur";
import BlocResultEmptySolidaire from "../../components/BlocResultEmptySolidaire";

const SearchInterfaceSolidaire = () => {
  const [connected, setConnected] = useState(null);
  const location = useLocation();
  const searchData = location?.state?.searchData;
  console.log("data search", searchData);
  useEffect(() => {
    const auth = localStorage.getItem("token");
    if (auth) {
      Services.getAll("check-token")
        .then((res) => {
          console.log("check", res.data);
          if (res.data.status === "success") {
            setConnected(true);
          } else {
            setConnected(false);
          }
        })
        .catch((err) => {
          setConnected(false);
        });
    } else {
      return setConnected(false);
    }
  }, [connected]);

  return (
    <>
      {connected !== null && <Header connected={connected} />}
      <Headerblue data={searchData?.input_data} />
      <div className="bg-grey">
        <div className="p-16 container-fluid-lg">
          {searchData?.conducteurSolidaire?.length > 0 ? (
            <div className="">
              <div className="row ">
                <div className="col text-center bl">
                  <p className="current-text text-center">Prix</p>
                  <p className="subtitle text-center mt-8">
                    {searchData.prix} €
                  </p>
                </div>
                <div className="col text-center bl">
                  <p className="current-text text-center">Durée</p>
                  <p className="subtitle text-center mt-8">
                    {searchData?.duration}
                  </p>
                </div>
                <div className="col text-center">
                  <p className="current-text text-center">Km</p>
                  <p className="subtitle text-center mt-8">
                    {searchData?.distance}
                  </p>
                </div>
              </div>
              <div className="row p-16">
                {searchData?.conducteurSolidaire.map((el) => {
                  return <BlocConducteur data={el} searchData={searchData} />;
                })}
              </div>
              <div className="col-lg-6 mx-auto">
                <BlocResultEmptySolidaire title={false} />
              </div>
            </div>
          ) : (
            <div className="col-lg-6 mx-auto">
              <BlocResultEmptySolidaire title={true} />
            </div>
          )}
        </div>
      </div>

      {/* {connected && <TopBar />} */}
      {/* <Footer /> */}
    </>
  );
};
export default SearchInterfaceSolidaire;
