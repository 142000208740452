import Modal from 'react-bootstrap/Modal';
import IconClose from '../assets/images/iconClose.svg'

function ModalAtchoum({open,setOpen,text,deleteAccount}) {
  return (
    <Modal show={open} onHide={()=>{setOpen(false)}}>
      <Modal.Header className="border-none d-flex justify-content-between">
          <Modal.Title className=" ">
            <h1 className="bleuM500">{text}</h1> 
            </Modal.Title>
            <button className="cta"  onClick={()=>setOpen(!open)}><img src={IconClose} alt="icon close"/></button>
         
        </Modal.Header>
   
    <Modal.Footer>
      <button className="btn-primary-s w-100" onClick={()=>{deleteAccount()}} >
        Je confirme
      </button>
      <button className='btn-secondary-s w-100' onClick={()=>{setOpen(false)}}>
        Annuler
      </button>
    </Modal.Footer>
  </Modal>
  );
}

export default ModalAtchoum;