import "../assets/styles/covoiturage.scss";
import Avatar from "../assets/images/conducteur.svg";
import AllerRetour from "../assets/images/aller-retour.svg";
import ArrowUp from "../assets/images/Arrow-right.svg";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkToken } from "../utils/checkToken";
import ModalConnexion from "./modalConnexion";
const BlocTrajet = ({ data,el, type, path, from,inputs_data,status,key }) => {
  // console.log({data})
  const navigate = useNavigate();
  const [modalLogin, setModalLogin] = useState(false);
  const [time, setTime] = useState({
    aller: { dept: "", arr: "" },
    retour: { dept: "", arr: "" },
  });
  const [formattedDate, setFormattedDate] = useState("");
  const [formattedDateRetour, setFormattedDateRetour] = useState("");
  const communes=JSON.parse(data?.communes)
  const reservation=data?.trajet_reservation?.filter((pa) => pa?.user_id ==localStorage.getItem('userData'))
  // console.log({reservation})
 const formatDate = (dateString, isReturn = false) => {
  console.log(dateString?.split(" "))
  const [datePart, timePart] = dateString?.length>0 && dateString?.split(" ");
  const [year, month, day] = datePart?.split("-").map(Number);
  const date = new Date(year, month - 1, day);

  const daysOfWeek = [
    "Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"
  ];
  const monthNames = [
    "janvier", "février", "mars", "avril", "mai", "juin",
    "juillet", "août", "septembre", "octobre", "novembre", "décembre"
  ];

  const dayName = daysOfWeek[date.getDay()];
  const monthName = monthNames[month - 1];
  const [hours, minutes] = timePart?.split(":");
  const durationHours = parseInt(data?.duration?.split("h")[0]) || 0;
  const durationMinutes = parseInt(data?.duration?.split("h")[1]) || 0;
  const totalMinutes = minutes + durationMinutes;
  const totalHours = parseInt(hours )+ durationHours + Math.floor(totalMinutes / 60); // Account for extra hours from minutes
  const finalMinutes = totalMinutes % 60; // Remainder of minutes after an hour
  const finalHours = totalHours >23 ? totalHours % 24 : totalHours; // Remainder of hours after a day
  const deptTime = `${hours}h${minutes}`;
  console.log({finalHours,totalHours})
  const arrTime = `${(parseInt(hours) + durationHours).toString().padStart(2, "0")}h${(parseInt(minutes) + durationMinutes).toString().padStart(2, "0")}`;
console.log('hereeeee',dateString)
  if (isReturn) {
    setTime((prev) => ({
      ...prev,
      retour: { dept: deptTime, arr: arrTime },
    }));
    setFormattedDateRetour(`${dayName} ${day.toString().padStart(2, "0")} ${monthName}`);
  } else {
    setTime((prev) => ({
      ...prev,
      aller: { dept: deptTime, arr: arrTime },
    }));
    setFormattedDate(`${dayName} ${day.toString().padStart(2, "0")} ${monthName}`);
  }
};

  const clickCardTrajet = () => {
    const url=`${path}${data?.id}`
    checkToken().then((isValid) => {
      isValid ? navigate(`${url}`,{state:{inputs_data:inputs_data}}) : setModalLogin(true);
    });
  };
 
  useEffect(() => {
    // if(data?.trajetregulier)
    if(data?.Trajetregulier && !inputs_data){
      // console.log('its me',reservation)
      setFormattedDate(reservation?.length>0 && reservation[0]?.dateReservation  ?reservation[0]?.dateReservation : 'Trajet régulier')
      setTime({
        aller: { dept: "", arr: "" },
        retour: { dept: "", arr: "" },
      })
    }
   if (data?.dateDepart && !data?.Trajetregulier) formatDate(data?.dateDepart);
   
    if (data?.dateRetour  && !data?.Trajetregulier) formatDate(data?.dateRetour, true);
    if (data?.isFlexibleDest  && !data?.Trajetregulier) setFormattedDateRetour("Flexible");
    if (data?.isFlexibleDep  && !data?.Trajetregulier) setFormattedDate("Flexible");
  }, [data]);
  return (
    <>
      {modalLogin && (
        <ModalConnexion open={modalLogin} setOpen={setModalLogin} />
      )}
      <div className="col-md-6 col-lg-4 col-xs-12" key={key}>
        <div className="bloc-trajet mt-8">
          <div onClick={clickCardTrajet} className="no-decoration">
            {from && from === "myTargets" && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="subtitle bleuM500">{data?.isFlexibleDest || data?.dateRetour ?`${communes?.dept} / ${communes?.dest}`:formattedDate}</p>
                <p className="tag-trajet">
                  {data?.typeTrajet === 0 ? "Trajet solidaire" : "Covoiturage"}
                </p>
              </div>
            )}
           
             {(inputs_data?.flexible) && (
              <div className="d-flex align-items-center justify-content-between">
                <p className="subtitle bleuM500">{ data?.dateDepart ? formattedDate:data?.Trajetregulier ?"Régulier":""}</p>

                
              </div>
            )}
           { data?.dateRetour || data?.isFlexibleDest ? 
           <>
           <div className="price d-flex align-items-center justify-content-between mt-16">
           <p className=" bleuM500 ">Aller - {formattedDate}</p>
             <p className="bleuM500 mb-0 text-end">
               {parseFloat(data?.prixTrajet)?.toFixed(2) || 0} €
             </p>
           </div>
             
           <div className="trip-details  pb-16 mt-16">
        
           <div className="trip-time">
             <span className=" exergue bleuM500 mb-20">
             {data?.dateDepart && time?.aller?.dept}
             </span>
             <span className=" legend bleu400 mb-20">{data?.duration}</span>
             <span className=" exergue bleuM500">{data?.dateDepart && time?.aller?.arr}</span>
           </div>
           {/* <img src={TripRight} alt="trip icon" className="driver-icon mr-8" /> */}

           <div className="ml-8 trip-address align-items-start">
             <p className="legend mb-0 bleuM500">{communes?.dept}</p>
             {/* <p>{data?.dateRetour || data?.isFlexibleDest ? 
               <img src={AllerRetour} alt="aller retour icon" />:''}</p> */}
             <p className="legend mb-0 bleuM500">{communes?.dest}</p>
           </div>
           
         </div>
         <p className="subtitle bleuM500 ">Retour - {formattedDateRetour}</p>
         <div className="trip-details border-b pb-16 mt-16">
           <div className="trip-time">
             <span className=" exergue bleuM500 mb-20">
               {!data?.isFlexibleDest && data?.dateDepart && time.retour.dept}
             </span>
             <span className=" legend bleu400 mb-20">{data?.duration}</span>
             <span className=" exergue bleuM500">{!data?.isFlexibleDest && data?.dateDepart && time.retour.arr}</span>
           </div>
           {/* <img src={TripRight} alt="trip icon" className="driver-icon mr-8" /> */}

           <div className="ml-8 trip-address align-items-start">
             <p className="legend mb-0 bleuM500">{communes?.dest}</p>
             {/* <p>{data?.dateRetour || data?.isFlexibleDest ? 
               <img src={AllerRetour} alt="aller retour icon" />:''}</p> */}
             <p className="legend mb-0 bleuM500">{communes?.dept}</p>
           </div>
           <div className="price text-end">
             
           </div>
         </div>
         </>
           : <div className="trip-details border-b pb-16 mt-16">
              <div className="trip-time">
                <span className=" exergue bleuM500 mb-20">
                  {data?.dateDepart && time.aller.dept}
                </span>
                <span className=" legend bleu400 mb-20">{data?.duration}</span>
                <span className=" exergue bleuM500">{time.aller.arr}</span>
              </div>
              {/* <img src={TripRight} alt="trip icon" className="driver-icon mr-8" /> */}

              <div className="ml-8 trip-address align-items-start">
                <p className="legend mb-0 bleuM500">{communes?.dept}</p>
                {/* <p>{data?.dateRetour || data?.isFlexibleDest ? 
                  <img src={AllerRetour} alt="aller retour icon" />:''}</p> */}
                <p className="legend mb-0 bleuM500">{communes?.dest}</p>
              </div>
              <div className="price text-end">
                <p className="bleuM500 mb-0 text-end">
                  {parseFloat(data?.prixTrajet)?.toFixed(2) || 0} €
                </p>
              </div>
            </div>
          }
         
            {from==="myTargets"
            ?  
            <div className=" mt-16">
          {status==="onHold" && el?.role==="conducteur"  && data?.trajet_reservation?.filter((el) => el.status === "onHold").length!==0 && data?.typeTrajet === 1?
          <> <p className="exergue"> 
            {data?.trajet_reservation?.filter((el) => el.status === "onHold").length} demandes
            <span className="ml-8 legend bleu400">{el?.nbr_place- el?.reserved_places} places disponible</span>
            </p>
           <div className="success mt-8">
           Acceptez ou refusez les passagers <img src={ArrowUp} alt="arrow up icon" />
           </div>
           </>
           :status==="onHold" && el?.role==="conducteur" && data?.trajet_reservation && data?.trajet_reservation?.filter((el) => el.status === "onHold").length!==0 && data?.typeTrajet === 0 ?
           <div className="success mt-8">
           Acceptez ou refusez le passager <img src={ArrowUp} alt="arrow up icon" />
           </div>
           :status==="onHold" && el?.role==="conducteur" 
           && (data?.trajet_reservation?.filter((el) => el.status === "onHold").length===0 || !data?.trajet_reservation)?
           <>
           <p className="exergue"> 
            Aucune demande
            <span className="ml-8 legend bleu400">{el?.nbr_place- el?.reserved_places} places disponible</span>
            </p>
           
           </>
           : status==="avenir" && el?.role==="conducteur" ?
           <div className="badge-confirme text-start">Trajet complet - {el?.reserved_places} passagers</div>
           :<div className="driver-details mt-16">
           <div className="driver-info">
             {data?.conducteurtrajet?.photo_profil ? (
               <img
                 src={data?.conducteurtrajet?.photo_profil}
                 alt="driver"
                 className="driver-icon mr-8"
               />
             ) : (
               <img src={Avatar} alt="driver" className="driver-icon mr-8" />
             )}
             <div className="driver-name-status">
               <p className="legend bleuM500 mb-0">
               {type==="demand" ? `${reservation?.reservation_user?.nom} ${reservation?.reservation_user?.prenom}`:`${data?.conducteurtrajet?.nom} ${data?.conducteurtrajet?.prenom}`}  
               </p>
               {data?.conducteurtrajet?.description !== "" &&
                 data?.conducteurtrajet?.documents?.length > 3 &&
                 data?.conducteurtrajet?.vehicule && type==="trajet" &&(
                   <div className="badge-confirme ">Conducteur confirmé</div>
                 )}
             </div>
           </div>
           <div className="seats text-end">
             <p className="legend bleu400 text-end">
             
              {reservation && reservation[0]?.status==='onHold'?
             'Demande envoyée' : 
             reservation && reservation[0]?.status==='accepted' ?'':'Demande refusée'}
              </p>
           </div>
         </div>}
          </div>
            :  <div className="driver-details mt-16">
            <div className="driver-info">
              {data?.conducteurtrajet?.photo_profil ? (
                <img
                  src={data?.conducteurtrajet?.photo_profil}
                  alt="driver"
                  className="driver-icon mr-8"
                />
              ) : (
                <img src={Avatar} alt="driver" className="driver-icon mr-8" />
              )}
              <div className="driver-name-status">
                <p className="legend bleuM500 mb-0">
                {`${data?.conducteurtrajet?.nom} ${data?.conducteurtrajet?.prenom}`}  
                </p>
                {data?.conducteurtrajet?.description !== "" &&
                  data?.conducteurtrajet?.documents?.length > 3 &&
                  data?.conducteurtrajet?.vehicule && type==="trajet" &&(
                    <div className="badge-confirme ">Conducteur confirmé</div>
                  )}
              </div>
            </div>
            <div className="seats">
              <p className="legend bleuM500">{data?.nbrPlace} places</p>
            </div>
          </div>
            }
            {/* Driver Information Block */}
          
          </div>
        </div>
      </div>
    </>
  );
};
export default BlocTrajet;
