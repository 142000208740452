import { useNavigate } from "react-router-dom";
import ConfirmationIcon from "../assets/images/confirmation.svg";
import Header from "../components/header";


const ConfirmationBloc = (props) => {
 
  // Handle form submission
  const navigate=useNavigate()

  return (
    <>
      {/* <div className="desktop">
        <Header connected={false} />
      </div> */}

      <div className="stepFinal">
        <div className="container-fluid-lg">
         
        </div></div>
        <div className=" col-lg-4 mx-auto text-center">
            <img src={ConfirmationIcon} alt="confirmation icon" />
            <h2 className="px-16 bleuM500 mt-32 text-center">{props.title}</h2>
          <p className="px-16 mt-8 exergue bleuM500 text-center">
         {props.text}
          </p>
        
          
         
        </div>
        <div className="bottom-white-block">
          <button
            className="btn-primary-s p-16 w-sm-100"
            onClick={()=>{navigate(props.link)}}
          >
            {props.cta}
          </button>
        </div>
      
    </>
  );
};

export default ConfirmationBloc;
