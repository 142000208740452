import React, { useEffect, useState } from "react";
import "../../assets/styles/trajets.scss";
import TopBar from "../../components/topBar";
import BlocTrajet from "../../components/blocTrajet";
import Services from "../../services/global.service";
import Header from "../../components/header";

const MesTrajets = () => {
  const [activeTab, setActiveTab] = useState("onHold"); // Track active tab
  const [allTrips, setAllTrips] = useState([]);
  const [filtredData, setFiltredData] = useState([]);

  const getMyTargets = () => {
    Services.getAll("filteredtrajets")
      .then((res) => {
        setAllTrips(res?.data || []);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const filterData = (status) => {
    if (!allTrips) return;

    let filtered;
    if (status === "onHold") {
      filtered = allTrips.filter((trip) => trip.status == 1);
    } else if (status === "avenir") {
      filtered = allTrips.filter((trip) => trip.status == 2);
    } else if (status === "passes") {
      filtered = allTrips.filter((trip) => trip.status == 0);
    } else {
      filtered = allTrips; // Show all trips for "tous"
    }
    console.log({filtered})
    setFiltredData(filtered);
  };

  useEffect(() => {
    getMyTargets();
  }, []);

  useEffect(() => {
    filterData(activeTab); // Re-run filter whenever activeTab or allTrips changes
  }, [activeTab, allTrips]);

  return (
    <>
      <Header connected={true} />
      <div className="bg-grey mes-trajets-container">
        <div className="bg-white mt-8">
          <div className="px-16">
            <h1 className="bleuM500">Mes trajets</h1>
            <div className="mes-trajets-tabs">
              <ul>
                <li
                  className={activeTab === "onHold" ? "active" : ""}
                  onClick={() => setActiveTab("onHold")}
                >
                  En attente
                </li>
                <li
                  className={activeTab === "avenir" ? "active" : ""}
                  onClick={() => setActiveTab("avenir")}
                >
                  A venir
                </li>
                <li
                  className={activeTab === "passes" ? "active" : ""}
                  onClick={() => setActiveTab("passes")}
                >
                  Passés
                </li>
                <li
                  className={activeTab === "tous" ? "active" : ""}
                  onClick={() => setActiveTab("tous")}
                >
                  Tous
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="px-16 col-lg-12 mx-auto">
          <div className="trip-cards row">
            {filtredData && filtredData.length !== 0 ? (
              filtredData.map((trajet) => (
                <BlocTrajet
                  key={trajet.id}
                  el={trajet}
                  data={trajet?.trajet}
                  type={trajet?.trajet?.type}
                  path="/mes-trajets/"
                  from={"myTargets"}
                  status={activeTab}
                />
              ))
            ) : (
              <p className="legend mt-16 text-center">Aucun trajet disponible.</p>
            )}
          </div>
        </div>

        <div className="h-xs-100 mb-5"></div>
        <TopBar />
      </div>
    </>
  );
};

export default MesTrajets;
