// Loader.jsx
import React from 'react';
// import './Loader.scss'; // Import the CSS file for styling

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
    </div>
  );
};

export default Loader;
