import React from "react";
import Avatar from "../../assets/images/Avatar.svg";
import { useNavigate } from 'react-router-dom';

function truncateText(text, maxLength) {
  return text?.length > maxLength ? text.slice(0, maxLength) + "..." : text;
}

function Message({ image, title, text, date, number, id }) {
  const navigate = useNavigate();
  const truncatedText = truncateText(text, 62);
  const truncatedTitle = truncateText(title, 18);
  function formatDate(dateString) {
    const date = new Date(dateString);
    const now = new Date();
    const timeDiff = Math.abs(now - date);
    const hoursDiff = Math.floor(timeDiff / (1000 * 60 * 60));

    const optionsDay = { weekday: 'short', day: 'numeric', month: 'short' };
    const optionsTime = { hour: '2-digit', minute: '2-digit' };
    // Check if the date is today
    if (date.toDateString() === now.toDateString()) {
      if (hoursDiff < 4) {
        if (hoursDiff === 0) {
          const minutesDiff = Math.floor(timeDiff / (1000 * 60));
          return `il y a ${minutesDiff} min`;
        }
        return `il y a ${hoursDiff} h`;
      }
      return `aujourd'hui à ${date.toLocaleTimeString('fr-FR', optionsTime)}`;
    } else {
      return `${date.toLocaleDateString('fr-FR', optionsDay)} `;
      //- ${date.toLocaleTimeString('fr-FR', optionsTime)}
    }
  }
  return (
    <>
      <div className="row mt-8 cursor-pointer" onClick={() => navigate('/discussion/' + id)}>
        <div className="col-2 mt-20">
          <img src={image || Avatar} alt="avatar" className="w-40 img-message" />
        </div>
        <div className="col-10 section-msg mt-20">
          <div className="d-flex justify-content-between">
            <span className="text-start title-msg font-Livvic font-16 name-container ">
              {truncatedTitle}

           
            </span>
            <span className="date-mesg">{formatDate(date)}</span>
          </div>
          <div className="row justify-content-between">
            <div className="col-10 current-text ">{truncatedText}</div>
            <div className="col-2 cercle-msg"> 
             
              {number > 0 && <div className="number-msg font-16">{number}</div>}
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  );
}

export default Message;
