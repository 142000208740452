import Autocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Services from "../services/global.service";
import CalendarAtchoum from "./calendar";
import { toast } from "react-toastify";

const FormTrajet = ({dataSearch}) => {
  const [depart, setDepart] = useState(dataSearch?.adresseDepart || "");
  const [destination, setDestination] = useState(dataSearch?.adresseDestination || "");
  const [departLatLng, setDepartLatLng] = useState(dataSearch?.adrDepartAltitude ? {lat:dataSearch?.adrDepartAltitude,lng:dataSearch?.adrDepartLongitude} : null);
  const [destinationLatLng, setDestinationLatLng] = useState(dataSearch?.adresseDesAltitude ? {lat:dataSearch?.adresseDesAltitude,lng:dataSearch?.adresseDesLongitude} : null);
  const [commune, setCommune] = useState(dataSearch?.communes || {dept:"",dest:""});
  const [departDateTime, setDepartDateTime] = useState('');
  const [arrivalDateTime, setArrivalDateTime] = useState('');
  const [seats, setSeats] = useState(dataSearch?.nbrPlace || '');
  const [comments, setComments] = useState('');
  const [postalCode, setPostalCode] = useState({dept:"",dest:""});

  const [modalCalendar, setModalCalendar] = useState(false);
  const time = dataSearch?.time || "08:00:00";  // Provide a default value if dataSearch?.time is undefined
  const [hours, minutes] = time.split(":"); 
 const formdetails= {
   
    "date": dataSearch?.dateDepart ,
    "time": {
        "hour": hours,
        "minute": minutes
    },
    "isFlexible": dataSearch?.flexible || false,
    "selectedDays": dataSearch?.selectedDates || [],
    "selectedPeriods": dataSearch?.selectedPeriods || []
}
  const [confirmationDetails, setConfirmationDetails] = useState({ depart: (dataSearch  && formdetails) ? formdetails : {}, retour: {} });
  const [forminput, setForminput] = useState(null);
  const [isGMapsLoaded, setIsGMapsLoaded] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
   // console.log('here',window.google)
    if(window.google) setIsGMapsLoaded(true)
    if (!window.google) {
      const gmapScriptEl = document.createElement("script");
      gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      gmapScriptEl.async = true;
      gmapScriptEl.defer = true;
      gmapScriptEl.onload = () => setIsGMapsLoaded(true);
      document.body.appendChild(gmapScriptEl);
    }
  }, []);

  const handleSelect = async (address, type) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      // console.log(results)
     
        const addressComponents = results[0].address_components;

       // Find the component with 'locality' type for city/commune
       const cityComponent = addressComponents.find(component => 
        component.types.includes('locality')
      );
      const postalCode = addressComponents.find(component =>
        component.types.includes('postal_code')
      );
      // Set the commune (city)
      // console.log(cityComponent)
      if (cityComponent && postalCode) {
        if (type === "destination") {
          if (
            address === depart || 
            (departLatLng && latLng.lat === departLatLng.lat && latLng.lng === departLatLng.lng)
          ) {
            alert("La destination doit être différente du départ.");
            return;
          }
          setDestination(address);
          setDestinationLatLng(latLng);
        } else {
          if (
            address === destination || 
            (destinationLatLng && latLng.lat === destinationLatLng.lat && latLng.lng === destinationLatLng.lng)
          ) {
            alert("Le départ doit être différent de la destination.");
            return;
          }
          setDepart(address);
          setDepartLatLng(latLng);
        }
        const cityName = cityComponent.long_name;
        const code = postalCode.long_name;
        setPostalCode((prevCommune) => {
        if (type === "destination") {
          return { ...prevCommune, dest: code }; // Set destination commune
        } else if (type === "depart") {
          return { ...prevCommune, dept: code }; // Set departure commune
        }
        return prevCommune;
      });
        setCommune((prevCommune) => {
          if (type === "destination") {
            return { ...prevCommune, dest: cityName }; // Set destination commune
          } else if (type === "depart") {
            return { ...prevCommune, dept: cityName }; // Set departure commune
          }
          return prevCommune;
        });
      } else  {
        console.error('Commune not found in the selected address');
        toast.error('Adresse sans code postal !!', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
         // theme: 'light', // Choose theme (light/dark)
          className: 'custom-toast-error'
  
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleModalCalendar = (value) => {
    setForminput(value);
    setModalCalendar(!modalCalendar);
  };

  const formatDate = (dateString, setState) => {
    const [datePart, timePart] = dateString.split(' ');
    const [year, month, day] = datePart.split('-').map(Number);
    const date = new Date(year, month - 1, day);
    
    const daysOfWeek = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    const monthNames = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    const dayName = daysOfWeek[date.getDay()];
    const monthName = monthNames[month - 1];
    const [hours, minutes] = timePart.split(':');
    
    const formattedDate = `${dayName} ${day.toString().padStart(2, '0')} ${monthName} à ${hours}h${minutes}`;
    setState(formattedDate);
  };

  useEffect(() => {
   if (Object.keys(confirmationDetails?.depart).length !== 0) {
      confirmationDetails.depart.isFlexible
        ? setDepartDateTime('Je suis flexible')
        : formatDate(confirmationDetails.depart.date, setDepartDateTime);
    }
   
     if (Object.keys(confirmationDetails?.retour).length !== 0) {
      console.log('flexible',confirmationDetails.retour)
      confirmationDetails.retour.isFlexible
        ? setArrivalDateTime('Je suis flexible')
        : formatDate(confirmationDetails.retour.date, setArrivalDateTime);
    }
    console.log(depart)
    setIsDisabled(!departLatLng || !destinationLatLng 
     // ||  (Object.keys(confirmationDetails?.retour).length === 0)
       ||(Object.keys(confirmationDetails?.depart).length === 0)
      ||  !seats)
  }, [confirmationDetails,seats,destination,depart]);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(confirmationDetails)
    const data = {
      adresseDepart: depart,
      adrDepartLongitude: departLatLng.lng,
      adrDepartAltitude:departLatLng.lat,
      adresseDestination: destination,
      adresseDesLongitude: destinationLatLng.lng,
      adresseDesAltitude:destinationLatLng.lat,
      dateDepart:confirmationDetails.depart.date,
      dateArrive:confirmationDetails.retour.date, // a changer date retour back et front
      nbrPlace:seats,
      commentaire:comments,
      communes:commune,
      postalCode:postalCode,
      isFlexibleDest: confirmationDetails?.retour?.isFlexible,
      selectedDayDest:confirmationDetails.retour?.selectedDays,
      selectedPeriodsDest:confirmationDetails?.retour?.selectedPeriods,
      isFlexibleDep: confirmationDetails?.depart?.isFlexible,
      selectedDayDept:confirmationDetails.depart?.selectedDays,
      selectedPeriodsDept:confirmationDetails?.depart?.selectedPeriods


    };
    console.log(data)

    Services.create(data, "trajet/rechercheTrajetSolidaire")
      .then((res) => {
        console.log("Response", res.data);
        navigate('/search-solidaire', { state: { searchData: res.data } });

      })
      .catch((err) => {
        console.log("Error", err);
      });
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="mb-3 p-16">
        {modalCalendar && (
          <CalendarAtchoum
            open={modalCalendar}
            setOpen={setModalCalendar}
            home={true}
            type={forminput}
            setConfirmationDetails={setConfirmationDetails}
            confirmationDetails={confirmationDetails}
          />
        )}

        {/* Autocomplete for Depart */}
        <div className="form-floating">
        {isGMapsLoaded &&  <Autocomplete
            value={depart}
            searchOptions={{ componentRestrictions: { country: ['fr'] },
            types: ["address"],
            strictBounds: true,
          }}
            onChange={setDepart}
            onSelect={(addr) => handleSelect(addr, "depart")}
            
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="floating-input-container">
                <input
                  {...getInputProps({ placeholder: "", className: "floating-input input-location  input-home radius-top" })}
                  id="floatingAdr"
                />
                <label htmlFor="floatingAdr" className="floating-label home-label">Adresse de départ</label>
                <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                  {loading ? <div>Loading...</div> : null}
                  {suggestions
                  // .filter((suggestion) => !suggestion.description.includes(destination))

                  .map((suggestion, index) => {
                    const style = { backgroundColor: suggestion.active ? "#41B6E6" : "#fff" };
                    return (
                      <div
                        key={index}
                        className="mapItem"
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Autocomplete>
      }
        </div>

        {/* Autocomplete for Destination */}
        <div className="form-floating">
        {isGMapsLoaded &&  
        <Autocomplete
            value={destination}
            searchOptions={{ componentRestrictions: { country: ['fr'] },
            types: ["address"],
          }}
            onChange={setDestination}
            onSelect={(addr) => handleSelect(addr, "destination")}
          >
            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
              <div className="floating-input-container mb-3">
                <input
                  {...getInputProps({ placeholder: "", className: "floating-input input-home radius-bottom" })}
                  id="floatingDestination"
                />
                <label htmlFor="floatingDestination" className="floating-label home-label">Adresse de destination</label>
                <div className={suggestions.length > 0 ? "container-autocomplete" : ""}>
                  {loading ? <div>Loading...</div> : null}
                  {suggestions
                  // .filter((suggestion) => !suggestion.description.includes(depart))
                    .map((suggestion, index) => {
                    const style = { backgroundColor: suggestion.active ? "#41B6E6" : "#fff" };
                    return (
                      <div
                        key={index}
                        className="mapItem"
                        {...getSuggestionItemProps(suggestion, { style })}
                      >
                        {suggestion.description}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </Autocomplete>
        }
        </div>

        {/* Departure and Arrival DateTime */}
        <div className="form-floating">
          <input
            type="text"
            value={departDateTime}
            onClick={() => handleModalCalendar('depart')}
            className="date-home input-home radius-top floating-input"
            placeholder="Date et heure de départ"
            readOnly
          />
        </div>

        <div className="floating-input-container">
          <input
            type="text"
            value={arrivalDateTime}
            onClick={() => handleModalCalendar('retour')}
            className="date-home input-home radius-bottom floating-input"
            placeholder="Date et heure de retour"
            readOnly
          />
        </div>

        {/* Number of Seats */}
        <div className="floating-input-container mt-8">
          <input
            type="number"
            value={seats}
            onChange={(e) => setSeats(e.target.value)}
            className="floating-input input-home"
            placeholder=""
            min={1}
          />
          <label htmlFor="seats" className="floating-label home-label">Nombre de places souhaitées</label>
        </div>

        {/* Comments */}
        <div className="floating-input-container mt-8">
          <textarea
            value={comments}
            onChange={(e) => setComments(e.target.value)}
            className="floating-input input-home"
            placeholder=""
          />
          <label htmlFor="comments" className="floating-label home-label">Commentaire</label>
        </div>

        <button type="submit" 
        className="btn-primary-s mt-16 w-100 p-16"
        disabled={isDisabled}
        >Rechercher un conducteur solidaire</button>
      </form>
    </>
  );
};

export default FormTrajet;
