import React, { useState } from "react";
import send from "../../assets/images/send.svg";

function NewMessage({ placeholderText,onSend }) {
  const [message,setMessage]=useState('')
  return (
    <>
      <div className="d-flex justify-content-between mt-100">
      <div className="floating-input-container w-100">
        <input
          type="text"
          placeholder={""}
          value={message}
          className="floating-input w-100 p-14 newMessage-container mr-8"
          onChange={(e)=>{setMessage(e.target.value)}}
          onKeyDown={(e) => {
            if (e.key === "Enter" && message?.trim().length>1) {
              e.preventDefault(); // Prevents a form submit if inside a form element
              onSend(message);
             setMessage('')

            }
          }}
        />
         <label htmlFor="prenom" className="floating-label bleuM500">
                    {placeholderText}
                  </label>
         </div>
        <button className="cta" 
        disabled={!message  || message?.trim().length<1}
        onClick={() =>{
           onSend(message)
           setMessage('')
           }}>
        <img src={send} alt="Send icon" className="send-msg" />

          </button>
      </div>
    </>
  );
}

export default NewMessage;
